import { Navigation, SubNavigationItem } from '@shopify/polaris';
import {
  AutomationMajor,
  AnalyticsMajor,
  BankMajor,
  CirclePlusMajor,
  CustomersMajor,
  FraudProtectUnprotectedMajor,
  HomeMajor,
  InventoryMajor,
  JobsMajor,
  NoteMajor,
  OrdersMajor,
  OrderStatusMinor,
  ProductsMajor,
  ReportsMajor,
  SettingsMajor,
  StoreStatusMajor,
} from '@shopify/polaris-icons';

type AdminMarkupProps = {
  toggleIsLoading: () => void;
  setNavItemActive: (navItem: string) => void;
};

export function AdminMarkup({ toggleIsLoading, setNavItemActive }: AdminMarkupProps) {
  /**
   * Products sub navigation
   */
  const productsSubNavigationMarkup: SubNavigationItem[] = [
    {
      label: 'Tutti i prodotti',
      url: '/admin/products',
      onClick: () => {
        toggleIsLoading();
        setNavItemActive('products');
      },
    },
    {
      label: 'Categorie',
      url: '/admin/products/categories',
      onClick: () => {
        toggleIsLoading();
        setNavItemActive('products');
      },
    },
  ];

  return (
    <>
      <Navigation.Section
        title="Home"
        items={[
          {
            label: 'Home',
            icon: HomeMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('home');
            },
            url: '/',
            exactMatch: true,
          },
          {
            label: 'Clienti',
            icon: CustomersMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('customers');
            },
            url: '/customers',
          },
          {
            label: 'Prodotti',
            icon: ProductsMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('questionnaires');
            },
            url: '/questionnaires',
          },
          {
            label: 'Preventivi',
            icon: NoteMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('quotes');
            },
            url: '/quotes',
          },
          {
            label: 'Polizze',
            icon: OrdersMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('policies');
            },
            url: '/policies',
          },
          {
            label: 'Sinistri',
            icon: FraudProtectUnprotectedMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('claims');
            },
            url: '/claims',
          },
        ]}
      />
      <Navigation.Section
        title="Analisi assicurative"
        items={[
          {
            label: 'Retail e Famiglie',
            icon: StoreStatusMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('reports family');
            },
            url: '/reports/family',
          },
          {
            label: 'Business (beta)',
            icon: OrderStatusMinor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('reports');
            },
            url: '/reports/business',
          },
        ]}
      />
      <Navigation.Section
        title="Analytics"
        items={[
          {
            label: 'Analytics',
            icon: AnalyticsMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('analytics');
            },
            url: '/analytics',
          },
        ]}
      />
      <Navigation.Section
        title="Admin"
        fill
        items={[
          {
            label: 'Utenti',
            icon: CustomersMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('users');
            },
            url: '/admin/users',
          },
          {
            label: 'Organizzazioni',
            icon: BankMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('organizations');
            },
            url: '/admin/organizations',
          },
          {
            label: 'Fabbrica prodotti',
            icon: ProductsMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('products');
            },
            secondaryAction: {
              url: '/admin/products',
              accessibilityLabel: 'Altri',
              icon: CirclePlusMajor as any,
              onClick: () => {
                toggleIsLoading();
                setNavItemActive('products');
              },
            },
            url: '/admin/products',
            subNavigationItems: productsSubNavigationMarkup,
          },
          {
            label: 'Compagnie',
            icon: InventoryMajor,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('companies');
            },
            url: '/admin/companies',
          },
          {
            label: 'Reports personalizzati',
            icon: ReportsMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('reports');
            },
            url: '/admin/reports',
          },
          {
            label: 'Automazioni',
            icon: AutomationMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('automations');
            },
            url: '/admin/automations',
          },
          {
            label: 'Professioni',
            icon: JobsMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('jobs');
            },
            url: '/admin/jobs',
          },
        ]}
      />
      <Navigation.Section
        items={[
          {
            label: 'Impostazioni',
            icon: SettingsMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('settings');
            },
            url: '/settings',
            matchPaths: ['/settings', '/settings/*'],
          },
        ]}
      />
    </>
  );
}
