import React, { useCallback, useEffect, useState } from 'react';
import { Button, DatePicker, Popover, Select, LegacyStack, TextField } from '@shopify/polaris';
import { CalendarMinor } from '@shopify/polaris-icons';
import { useUser } from '../../utils/PrivateRoute';

import './AnalyticsDatePicker.scss';

type AnalyticsDatePickerProps = {
  handleAnalytics?: any;
  handleAnalyticsPartner?: any;
  setLoading: any;
};

export function AnalyticsDatePicker({ handleAnalytics, handleAnalyticsPartner, setLoading }: AnalyticsDatePickerProps) {
  const { user } = useUser();
  /**
   * Popover
   */
  const [popoverActive, setPopoverActive] = useState(false);
  const [defaultInput, setDefaultInput] = useState('Questo mese');
  const [input, setInput] = useState('Questo mese');

  const togglePopoverActive = useCallback(() => setPopoverActive((popoverActive) => !popoverActive), []);

  const date = new Date();
  const [{ month, year }, setDate] = useState({ month: date.getMonth(), year: date.getFullYear() });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(date.getFullYear(), date.getMonth(), 1),
    end: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
  });

  const dateOptions = new Map([
    [
      'Questo mese',
      {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      },
    ],
    [
      'Mese scorso',
      {
        start: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      },
    ],
    [
      'Da inizio anno',
      {
        start: new Date(new Date().getFullYear(), 0, 1),
        end: new Date(),
      },
    ],
    [
      'Da sempre',
      {
        start: new Date(new Date().getFullYear(), -24),
        end: new Date(),
      },
    ],
    [
      'Personalizzato',
      {
        start: new Date(),
        end: new Date(),
      },
    ],
  ]);

  /** Select handler */
  const handleSelectChange = useCallback(
    (e: any) => {
      setInput(e);
      setSelectedDates({
        // @ts-ignore
        start: dateOptions.get(e).start,
        // @ts-ignore
        end: dateOptions.get(e).end,
      });
      setDate({
        // @ts-ignore
        month: dateOptions.get(e)?.start.getMonth(),
        // @ts-ignore
        year: dateOptions.get(e)?.start.getFullYear(),
      });
    },
    [dateOptions],
  );

  const activator = (
    <Button icon={CalendarMinor as any} onClick={togglePopoverActive}>
      {defaultInput}
    </Button>
  );

  /**
   * Date picker
   */
  const handleMonthChange = useCallback((month: any, year: any) => setDate({ month, year }), []);

  /** Date picker selected date handler */
  const handleSelectedDate = useCallback(
    (e: any) => {
      setSelectedDates({
        start: e.start,
        end: e.end,
      });

      // Check if map contains this set of date
      // @ts-ignore
      for (const [key, value] of dateOptions) {
        if (key === 'Personalizzato') continue;

        if (new Date(value.start).toDateString() !== e.start.toDateString() || new Date(value.end).toDateString() !== e.end.toDateString()) {
          // Add Personalizzato to dateOptions
          dateOptions.set('Personalizzato', {
            start: e.start,
            end: e.end,
          });
          setInput('Personalizzato');
        } else if (new Date(value.start).toDateString() === e.start.toDateString() && new Date(value.end).toDateString() === e.end.toDateString()) {
          // Remove Personalizzato
          if (dateOptions.has('Personalizzato')) {
            dateOptions.delete('Personalizzato');
          }

          setInput(key);
          break;
        }
      }
    },
    [dateOptions],
  );

  const parseDateLabel = (date: Date) => {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
  };

  /**
   * Data fetching:
   * - fetch analytics ADMIN & PARTNER
   */

  function fetchAnalytics() {
    if (user.role === 'admin') {
      const fetchAnalyticsAdmin = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/analytics/overview`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
            body: JSON.stringify({
              start: selectedDates.start,
              end: selectedDates.end,
            }),
          });
          const data = await response.json();

          if (data.status === 'success') {
            const res = {
              customers: data.data.customers,
              quotes: data.data.quotes,
              policies: data.data.policies,
              premioNetto: data.data.premioNetto,
              organizations: data.data.organizations,
              //organizationsTypes: response.data.organizationsTypes,
            };
            handleAnalytics(res);
          } else {
            const res = {
              customers: [],
              quotes: [],
              policies: [],
              premioNetto: [],
              organizations: [],
              //organizationsTypes: [],
            };
            handleAnalytics(res);
          }
        } catch (error) {
          console.log(error);
        }
      };
      return fetchAnalyticsAdmin();
    }
    // Fetch analytics for partner
    const fetchAnalyticsPartner = async () => {
      try {
        const response = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/analytics/partner', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
          body: JSON.stringify({
            start: selectedDates.start,
            end: selectedDates.end,
          }),
        });
        const data = await response.json();

        if (data.status === 'success') {
          const res = {
            customers: data.data.customers,
            quotes: data.data.quotes,
            policies: data.data.policies,
            premioNetto: data.data.premio,
          };
          handleAnalyticsPartner(res);
        } else {
          const res = {
            customers: [],
            quotes: [],
            policies: [],
            premioNetto: [],
          };
          handleAnalyticsPartner(res);
        }
      } catch (error) {
        console.log(error);
      }
    };
    return fetchAnalyticsPartner();
  }

  useEffect(() => {
    setLoading(true);
    fetchAnalytics().then(() => setLoading(false));
  }, [setLoading]);

  /**
   * Submit handler
   */
  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      setDefaultInput(input);

      const response = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/analytics/overview', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
        body: JSON.stringify({
          start: selectedDates.start,
          end: selectedDates.end,
        }),
      });
      const data = await response.json();

      if (data.status === 'success') {
        setPopoverActive(false);
        const res = {
          customers: data.data.customers,
          quotes: data.data.quotes,
          policies: data.data.policies,
          premioNetto: data.data.premioNetto,
          organizations: data.data.organizations,
          //organizationsTypes: response.data.organizationsTypes,
        };
        handleAnalytics(res);
      } else {
        const res = {
          customers: [],
          quotes: [],
          policies: [],
          premioNetto: [],
          organizations: [],
          //organizationsTypes: [],
        };
        handleAnalytics(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates.end, selectedDates.start, setLoading]);

  /**
   * Submit handler
   */
  const handleSubmitPartner = useCallback(async () => {
    try {
      setDefaultInput(input);

      const response = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/analytics/partner', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
        body: JSON.stringify({
          start: selectedDates.start,
          end: selectedDates.end,
        }),
      });
      const data = await response.json();

      if (data.status === 'success') {
        setPopoverActive(false);
        const res = {
          customers: data.data.customers,
          quotes: data.data.quotes,
          policies: data.data.policies,
          premioNetto: data.data.premio,
        };
        handleAnalyticsPartner(res);
      } else {
        const res = {
          customers: [],
          quotes: [],
          policies: [],
          premioNetto: [],
        };
        handleAnalyticsPartner(res);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates.end, selectedDates.start]);

  const popoverMarkup = (
    <div>
      <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive} ariaHaspopup={false} preferredAlignment="left" fluidContent>
        <Popover.Pane sectioned>
          <LegacyStack vertical wrap>
            <Select label="Intervallo di date" options={Array.from(dateOptions.keys())} value={input} onChange={handleSelectChange} />
            <LegacyStack distribution="fillEvenly">
              <TextField autoComplete="on" label="Inizio" value={parseDateLabel(selectedDates.start)} onChange={undefined} />
              <TextField autoComplete="on" label="Fine" value={parseDateLabel(selectedDates.end)} onChange={undefined} />
            </LegacyStack>
            <div className="popoverDatePicker">
              <DatePicker
                month={month}
                year={year}
                onChange={handleSelectedDate}
                onMonthChange={handleMonthChange}
                selected={selectedDates}
                multiMonth
                allowRange
              />
            </div>
          </LegacyStack>
        </Popover.Pane>
        <Popover.Pane fixed>
          <Popover.Section>
            <LegacyStack distribution="equalSpacing">
              <div>
                <Button
                  onClick={() => {
                    setPopoverActive(false);
                  }}
                >
                  Annulla
                </Button>
              </div>
              <div>
                {user && user.role === 'admin' ? (
                  <Button primary disabled={input === defaultInput ? (input === 'Personalizzato' ? false : true) : false} onClick={handleSubmit}>
                    Applica
                  </Button>
                ) : null}
                {user && user.role === 'partner' ? (
                  <Button primary disabled={input === defaultInput ? (input === 'Personalizzato' ? false : true) : false} onClick={handleSubmitPartner}>
                    Applica
                  </Button>
                ) : null}
              </div>
            </LegacyStack>
          </Popover.Section>
        </Popover.Pane>
      </Popover>
    </div>
  );

  return popoverMarkup;
}
