import { Navigation, SubNavigationItem } from '@shopify/polaris';
import { CirclePlusMajor, HomeMajor, KeyMajor, ProductsMajor, ReportsMajor, SettingsMajor } from '@shopify/polaris-icons';

type AffinityMarkupProps = {
  toggleIsLoading: () => void;
  setNavItemActive: (navItem: string) => void;
  resourcesSubNavigationMarkup: SubNavigationItem[];
  bookIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export function AffinityMarkup({ toggleIsLoading, setNavItemActive, resourcesSubNavigationMarkup, bookIcon }: AffinityMarkupProps) {
  return (
    <>
      <Navigation.Section
        title="Dashboard"
        items={[
          {
            label: 'Dashboard',
            icon: HomeMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('dashboard');
            },
            url: '/',
            exactMatch: true,
          },
          {
            label: 'Prodotti attivi',
            icon: ProductsMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('products');
            },
            url: '/questionnaires',
            exactMatch: true,
          },
        ]}
      />
      <Navigation.Section
        title="Guide Risorse"
        items={[
          {
            label: 'Guide',
            icon: bookIcon as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('guide');
            },
            url: '/guide',
            exactMatch: true,
          },
          {
            label: 'Risorse Marketing',
            icon: bookIcon as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('resources');
            },
            secondaryAction: {
              url: '/resources',
              accessibilityLabel: 'Altri',
              icon: CirclePlusMajor as any,
              onClick: () => {
                toggleIsLoading();
                setNavItemActive('resources');
              },
            },
            url: '/resources',
            subNavigationItems: resourcesSubNavigationMarkup,
          },
        ]}
      />
      <Navigation.Section
        title="Presenta un partner"
        items={[
          {
            label: 'Contatti',
            icon: ReportsMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('contacts');
            },
            url: '/contacts',
            exactMatch: true,
          },
        ]}
      />
      <Navigation.Section
        title="Organizzazione"
        fill
        items={[
          {
            label: 'Dettagli organizzazione',
            icon: KeyMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('api');
            },
            url: '/api',
            matchPaths: ['/api', '/api/*'],
          },
        ]}
      />

      <Navigation.Section
        items={[
          {
            label: 'Impostazioni',
            icon: SettingsMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('settings');
            },
            url: '/settings',
            matchPaths: ['/settings', '/settings/*'],
          },
        ]}
      />
    </>
  );
}
