import { Organization } from './organization';

// Discount application type
export type DiscountApplication = {
  type: 'discount_code' | 'manual';
  description?: string;
  value: number;
  value_type: 'fixed_amount' | 'percentage';
};

// Discount code type
export type DiscountCode = {
  code: string;
  amount: number;
  type: 'fixed_amount' | 'percentage';
};

// Order status enum
export enum OrderStatus {
  DRAFT = 'draft',
  QUOTE = 'quote',
  TO_BE_QUOTED = 'to_be_quoted',
  QUOTE_ACCEPTED = 'quote_accepted',
  QUOTE_REJECTED = 'quote_rejected',
  TO_BE_COMPLETED = 'to_be_completed',
  PAID = 'paid',
  EMITTED = 'emitted',
  EXPIRED = 'expired',
  BLOCKED = 'blocked',
}

// Order timeline type
export type OrderTimeline = {
  status:
    | OrderStatus.DRAFT
    | OrderStatus.QUOTE
    | OrderStatus.TO_BE_QUOTED
    | OrderStatus.QUOTE_ACCEPTED
    | OrderStatus.QUOTE_REJECTED
    | OrderStatus.TO_BE_COMPLETED
    | OrderStatus.PAID
    | OrderStatus.EMITTED
    | OrderStatus.EXPIRED
    | OrderStatus.BLOCKED;
  date_updated: Date;
};

// Quotation metadata type
export type QuotationMetadata = {
  label: string;
  value: string | number | QuotationMetadata[][] | QuotationMetadata[];
  type: 'text' | 'number' | 'currency' | 'array' | 'date' | 'select';
  api_field?: string;
};

// Order metadata type
export type OrderMetadata = {
  product: string;
  fields: QuotationMetadata[];
};

// Order commission type
export type OrderCommission = {
  /** The product */
  product: string;
  /** The commission amount (e.g. 10%)*/
  value: number;
  /** The commission type */
  type: string;
  /** The commission value (e.g. 10€ if the total is 100 and amount 10)*/
  total_value: number;
};

// Order file type enum
export enum OrderFileType {
  QUOTE = 'quote',
  POLICY = 'policy',
  RENEWAL = 'renewal',
}

// Order file type
type OrderFile = {
  /** The file _id */
  _id?: string;
  /** The file name stored */
  key: string;
  /** The file original name */
  title: string;
  /** The file type */
  file_type: 'quote' | 'policy' | 'renewal';
};

// Order type
export type Order = {
  _id: string;
  line_items: Array<any>;
  user: any;
  status: string;
  /** The organization that has sold the products */
  organization: string | Organization;
  subtotal_price: number;
  /** The management price: the amount the insurer can ask for its job */
  management_price?: number;
  /** The total price */
  total_price: number;
  /** The order commissions */
  commissions?: OrderCommission[];
  /** The order notes */
  notes?: string;
  discount_application?: DiscountApplication;
  discount_code?: DiscountCode;
  /** The policy start date */
  date_start?: Date;
  /** The policy end date */
  date_end?: Date;
  /** Order timeline */
  timeline?: OrderTimeline[];
  /** Order transactions */
  transactions?: any;
  /** Metadata */
  metadata?: OrderMetadata[];
  /** The order sequential numbers (is an array since an order can be in status quote and later in paid) */
  sequential_numbers: any[];
  /** The order inclusion numbers (present only if there are group policies) */
  inclusion_numbers?: any[];
  /** The order has been registered to the insurance portal */
  order_registered_on_portal?: boolean;
  /** The policy number on the company portal */
  policy_number_on_portal?: string;
  /** The order files */
  files?: OrderFile[];
  /** System information */
  date_created: Date;
  date_updated: Date;
};
