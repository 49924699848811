export enum CompanyFileTypes {
  MANDATE = 'mandate',
  COMMISSION_TABLE = 'commission_table',
  OTHER = 'other',
}

// Company file
export interface CompanyFile extends File {
  /** The file name stored */
  key: string;
  /** The file original name */
  title: string;
  /** The file type */
  file_type?: CompanyFileTypes;
}

/**
 * Company interface
 */

type CompanyReferent = {
  /** The referent name */
  name?: string;
  /** The referent lastname */
  lastname?: string;
  /** The referent email */
  email?: string;
  /** The referent phone */
  phone?: string;
};

type CompanyBilling = {
  /** The billing email */
  email?: string;
  /** The billing day of month */
  day?: number;
};

export interface Company {
  /** The company id */
  _id?: string;
  /** The company name */
  name: string;
  /** The company type */
  type: 'company' | 'broker' | 'coverholder';
  /** The company referent */
  referent?: CompanyReferent;
  /** The company billing informations */
  billing?: CompanyBilling;
  /** The company platform link */
  platform_link?: string;
  /** The company platform username */
  platform_username?: string;
  /** The company platform password */
  platform_password?: string;
  /** The company phone number */
  phone?: string;
  /** The company logo */
  logo?: CompanyFile;
  /** The company documents */
  documents?: CompanyFile[];
}
