/**
 * Generate the Italian fiscal code.
 * @param {string} name - The person's first name.
 * @param {string} lastname - The person's last name (surname).
 * @param {Date} birth_date - The person's birth date.
 * @param {string} birth_city - The code of the city of birth (already known or stored).
 * @param {string} gender - The person's gender: 'M' (male) or 'F' (female).
 */
export const generateFiscalCode = (name: string, lastname: string, birth_date: Date, birth_city: string, gender: string): string => {
  // Check for missing parameters
  if (!name?.trim()) {
    throw new Error('Name is missing');
  }
  if (!lastname?.trim()) {
    throw new Error('Lastname is missing');
  }
  if (!birth_date) {
    throw new Error('Birth date is missing');
  }
  if (!birth_city?.trim()) {
    throw new Error('Birth city is missing');
  }
  if (!gender?.trim()) {
    throw new Error('Gender is missing');
  }

  // 1. Extract 3 letters from the surname
  const threeLettersSurname = extractSurnameCode(lastname);

  // 2. Extract 3 letters from the name
  const threeLettersName = extractNameCode(name);

  // 3. Year (last two digits)
  const year = birth_date.getFullYear() % 100;

  // 4. Month (according to the official coding 'A'=January, 'B'=February, etc.)
  const monthCode = 'ABCDEHLMPRST'[birth_date.getMonth()]; // 0 -> 'A', 1 -> 'B', etc.

  // 5. Day of birth (if female, add 40)
  let day = birth_date.getDate();
  if (gender.toUpperCase() === 'F') {
    day += 40;
  }
  const dayStr = day.toString().padStart(2, '0');

  // 6. Birth city code (assuming `birth_city` is already the correct 4-char code)
  //    If you only have a city name, you must map city -> location code externally.
  const birthplaceCodePadded = birth_city.toUpperCase().padEnd(4, 'X');

  // 7. Concatenate partial code
  const partialCode = threeLettersSurname + threeLettersName + year.toString().padStart(2, '0') + monthCode + dayStr + birthplaceCodePadded;

  // 8. Calculate control character
  const controlCharacter = calculateControlCharacter(partialCode);

  return partialCode + controlCharacter;
};

/**
 * Extract 3 letters for the surname (cognome).
 *  - Take the 1st, 2nd, and 3rd consonant if possible.
 *  - If fewer than 3 consonants, add vowels in order.
 *  - If still < 3 characters, pad with 'X'.
 */
function extractSurnameCode(surname: string): string {
  // 1. Remove spaces, punctuation; consider as one word
  const cleaned = surname
    .replace(/\s+/g, '') // remove spaces
    .replace(/[^a-zA-Z]/g, '') // keep only letters
    .toUpperCase();

  // 2. Separate consonants and vowels
  const consonants: string[] = [];
  const vowels: string[] = [];
  for (let i = 0; i < cleaned.length; i++) {
    const c = cleaned[i];
    if (/[AEIOU]/.test(c)) {
      vowels.push(c);
    } else {
      consonants.push(c);
    }
  }

  // 3. Compose the 3 letters
  const result: string[] = [];

  // Take up to the first 3 consonants
  for (let i = 0; i < consonants.length && result.length < 3; i++) {
    result.push(consonants[i]);
  }

  // If still less than 3 chars, add vowels
  for (let i = 0; i < vowels.length && result.length < 3; i++) {
    result.push(vowels[i]);
  }

  // If still < 3, pad with X
  while (result.length < 3) {
    result.push('X');
  }

  return result.join('');
}

/**
 * Extract 3 letters for the name (nome).
 *  - If at least 4 consonants, take the 1st, 3rd, and 4th.
 *  - If fewer than 4 consonants, take the first 3 consonants.
 *  - If fewer than 3 total, add vowels, then pad with 'X' if needed.
 */
function extractNameCode(name: string): string {
  // 1. Remove spaces, punctuation
  const cleaned = name
    .replace(/\s+/g, '')
    .replace(/[^a-zA-Z]/g, '')
    .toUpperCase();

  // 2. Separate consonants and vowels
  const consonants: string[] = [];
  const vowels: string[] = [];
  for (let i = 0; i < cleaned.length; i++) {
    const c = cleaned[i];
    if (/[AEIOU]/.test(c)) {
      vowels.push(c);
    } else {
      consonants.push(c);
    }
  }

  // 3. Compose the 3 letters for the name
  const result: string[] = [];

  if (consonants.length >= 4) {
    // If we have 4+ consonants, take 1st, 3rd, and 4th
    result.push(consonants[0], consonants[2], consonants[3]);
  } else if (consonants.length >= 3) {
    // Otherwise, if we have at least 3, take those 3
    result.push(consonants[0], consonants[1], consonants[2]);
  } else {
    // If we have fewer than 3 consonants, take whatever consonants we have
    for (let i = 0; i < consonants.length && result.length < 3; i++) {
      result.push(consonants[i]);
    }
    // Then add vowels if still needed
    for (let i = 0; i < vowels.length && result.length < 3; i++) {
      result.push(vowels[i]);
    }
    // Pad with X if still needed
    while (result.length < 3) {
      result.push('X');
    }
  }

  return result.join('');
}

/**
 * Calculate the control character for the given partial code (15 characters).
 * The 16th character is calculated using the "tabella di controllo".
 */
function calculateControlCharacter(partialCode: string): string {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  // Map for odd positions (1-based index)
  const oddMap: Record<string, number> = {
    '0': 1,
    '1': 0,
    '2': 5,
    '3': 7,
    '4': 9,
    '5': 13,
    '6': 15,
    '7': 17,
    '8': 19,
    '9': 21,
    A: 1,
    B: 0,
    C: 5,
    D: 7,
    E: 9,
    F: 13,
    G: 15,
    H: 17,
    I: 19,
    J: 21,
    K: 2,
    L: 4,
    M: 18,
    N: 20,
    O: 11,
    P: 3,
    Q: 6,
    R: 8,
    S: 12,
    T: 14,
    U: 16,
    V: 10,
    W: 22,
    X: 25,
    Y: 24,
    Z: 23,
  };

  let sum = 0;
  for (let i = 0; i < partialCode.length; i++) {
    const char = partialCode[i];
    /**
     * Positions are 1-based.
     * - Odd positions use the oddMap values (i.e., 1, 3, 5, etc. => i % 2 === 0 for 0-based).
     * - Even positions use the "normal" value (digit or A=0, B=1, etc.).
     */
    const isOddPosition = (i + 1) % 2 !== 0;
    if (isOddPosition) {
      // Use oddMap
      sum += oddMap[char];
    } else {
      // Even position => numeric or alpha index
      if (/[0-9]/.test(char)) {
        sum += parseInt(char, 10);
      } else {
        sum += alphabet.indexOf(char);
      }
    }
  }

  // The control character is the remainder of sum % 26, mapped to A-Z
  return alphabet[sum % 26];
}
