import React, { useCallback, useState } from 'react';

import { Navigation, SubNavigationItem } from '@shopify/polaris';
import { navigationContextControlMarkup } from '../NavigationContextControlMarkup';
import { AffinityMarkup } from './components/AffinityMarkup';
import { BrokerMarkup } from './components/BrokerMarkup';
import { AdminMarkup } from './components/AdminMarkup';

const BookIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path d="M6.75 6a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5Z" />
    <path d="M6.75 9a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5Z" />
    <path d="M6.75 12a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5Z" />
    <path d="M12 6.75a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75Z" />
    <path d="M12 9.75a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75Z" />
    <path
      fillRule="evenodd"
      d="M7.586 3.5c.87 0 1.714.273 2.414.771a4.164 4.164 0 0 1 2.414-.771h2.336c.966 0 1.75.784 1.75 1.75v9.5a1.75 1.75 0 0 1-1.75 1.75h-.238a9.25 9.25 0 0 0-3.161.557l-1.095.398a.75.75 0 0 1-.493.007l-1.46-.487a9.25 9.25 0 0 0-2.926-.475h-.127a1.75 1.75 0 0 1-1.75-1.75v-9.5c0-.966.784-1.75 1.75-1.75h2.336Zm-2.586 1.75a.25.25 0 0 1 .25-.25h2.336c.608 0 1.194.208 1.664.584v10.125l-.473-.157a10.75 10.75 0 0 0-3.4-.552h-.127a.25.25 0 0 1-.25-.25v-9.5Zm5.75 10.43v-10.096a2.664 2.664 0 0 1 1.664-.584h2.336a.25.25 0 0 1 .25.25v9.5a.25.25 0 0 1-.25.25h-.238a10.75 10.75 0 0 0-3.674.647l-.088.032Z"
    />
  </svg>
);

export function NavigationMarkup({ user }: any) {
  const [navItemActive, setNavItemActive] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const toggleIsLoading = useCallback(() => setIsLoading((isLoading) => !isLoading), []);

  const location = window.location.pathname;

  if (!user) {
    return null;
  }

  /**
   * Resources Marketing
   */
  const resourcesSubNavigationMarkup: SubNavigationItem[] = [
    {
      label: 'Banners',
      url: '/resources/banners',
      onClick: () => {
        toggleIsLoading();
        setNavItemActive('resources');
      },
    },
    {
      label: 'Logo',
      url: '/resources/logo',
      onClick: () => {
        toggleIsLoading();
        setNavItemActive('resources');
      },
    },
    {
      label: 'Social',
      url: '/resources/social',
      onClick: () => {
        toggleIsLoading();
        setNavItemActive('resources');
      },
    },
  ];

  const navigationMarkupFinal = (
    <Navigation location={location} contextControl={navigationContextControlMarkup}>
      {/* Check if user is admin */}
      {user && user.role === 'admin' && <AdminMarkup toggleIsLoading={toggleIsLoading} setNavItemActive={setNavItemActive} />}
      {/* Check if user is partner */}
      {user && user.role === 'partner' && (
        <BrokerMarkup
          toggleIsLoading={toggleIsLoading}
          setNavItemActive={setNavItemActive}
          resourcesSubNavigationMarkup={resourcesSubNavigationMarkup}
          bookIcon={BookIcon}
        />
      )}
      {/* Check if user is affinity */}
      {user && user.role === 'affinity' && (
        <AffinityMarkup
          toggleIsLoading={toggleIsLoading}
          setNavItemActive={setNavItemActive}
          resourcesSubNavigationMarkup={resourcesSubNavigationMarkup}
          bookIcon={BookIcon}
        />
      )}
    </Navigation>
  );

  return navigationMarkupFinal;
}
