import { useCallback, useRef, useState } from 'react';

import { Frame, Layout, Page, Toast, Banner, Modal, TextField, FormLayout } from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup } from '../../../components';

import { useUser } from '../../../utils/PrivateRoute';
import { JobList } from './components/JobList';
import axios, { AxiosError } from 'axios';

export function Jobs() {
  const { user } = useUser();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = () => {
    setMobileNavigationActive((data) => !data);
  };

  const [update, setUpdate] = useState(false);
  const [active, setActive] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [conflictError, setConflictError] = useState(false);
  const [counterError, setCounterError] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toggleDeleted = useCallback(() => setDeleted((deleted) => !deleted), []);

  /**
   * Job modal
   */
  const [job, setJob] = useState('');
  const [jobError, setJobError] = useState(false);
  const [jobModalActive, setJobModalActive] = useState(false);
  const handleJobModal = useCallback(() => setJobModalActive((jobModalActive) => !jobModalActive), []);

  const handleJobChange = useCallback(
    (value: string) => {
      if (jobError) setJobError(false);
      setJob(value);
    },
    [jobError],
  );

  /**
   * Handle Save
   */
  const handleSave = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL ?? '/api'}/admin/jobs`,
        { job: job },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        setJob('');
        setUpdate((update) => !update);
        setActive(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      const status = axiosError.response?.status;
      console.log(error);

      if (status === 409) {
        setConflictError(true);
      } else {
        setSaveError(true);
      }
    } finally {
      setButtonSpinning(false);
      handleJobModal();
    }
  }, [job, setJob, setUpdate, handleJobModal]);

  const jobModalMarkup = jobModalActive ? (
    <Modal
      title="Aggiungi professione"
      open={jobModalActive}
      onClose={handleJobModal}
      primaryAction={{
        content: 'Aggiungi',
        onAction: handleSave,
        loading: buttonSpinning,
      }}
    >
      <Modal.Section>
        <FormLayout>
          <TextField label="Professione" type="text" value={job} onChange={handleJobChange} autoComplete="off" />
        </FormLayout>
      </Modal.Section>
    </Modal>
  ) : null;

  /**
   * Error markups & toast
   */
  const toastMarkup = active ? <Toast content="I dati sono stati salvati con successo." onDismiss={toggleActive} /> : null;
  const toastDeletedMarkup = deleted ? <Toast content="Le professioni sono state eliminate." onDismiss={toggleDeleted} /> : null;

  const saveErrorMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const conflictErrorMarkup = conflictError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Esiste già una professione con questo nome.</p>
      </Banner>
    </Layout.Section>
  );

  const counterErrorMarkup = counterError && (
    <Layout.Section>
      <Banner title={'Una o più professioni non possono essere rimosse'} status="critical" onDismiss={() => setCounterError(false)}>
        <p>Si è pregati di controllare e riprovare più tardi. Se il problema persiste puoi contattare il supporto.</p>
      </Banner>
    </Layout.Section>
  );

  // ---- Page markup ----
  const pageMarkup = (
    <Page
      title="Professioni"
      primaryAction={{
        content: 'Aggiungi professione',
        primary: true,
        onAction: () => handleJobModal(),
      }}
    >
      <Layout>
        {/* Banner */}
        {saveErrorMarkup}
        {counterErrorMarkup}
        {conflictErrorMarkup}

        {/* List */}
        <Layout.Section>
          <JobList
            setActive={setActive}
            setSaveError={setSaveError}
            setCounterError={setCounterError}
            user={user}
            update={update}
            setUpdate={setUpdate}
            toggleDeleted={toggleDeleted}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {pageMarkup}
      {toastMarkup}
      {toastDeletedMarkup}
      {jobModalMarkup}
    </Frame>
  );
}
