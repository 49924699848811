import { LegacyCard, EmptyState, Badge, Filters, Text, Pagination, Select, IndexTable, Link, useIndexResourceState } from '@shopify/polaris';
import React, { useCallback, useState, useEffect } from 'react';
import { parseClaimType, parseDateLabel, renderClaimStatusBadge } from '../../utils/Common';

import styles from './ClaimList.module.css';
import axios from 'axios';
import { IClaim } from '../../types/claim';
import { IAccident } from '../../types/accident';

export function ClaimList({ setActive, setSaveError }: any) {
  const [queryValue, setQueryValue] = useState('');
  const [taggedWith, setTaggedWith] = useState(null);
  const [sortValue, setSortValue] = useState('DATE_CREATED_DESC');
  const [items, setItems] = useState<any>([]);
  const [total, setTotal] = useState(-1);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(true);
  const [frontItems, setFrontItems] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  const resourceName = {
    singular: 'sinistro',
    plural: 'sinistri',
  };

  /**
   * Data fetching
   */
  useEffect(() => {
    const fetchCaims = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/claims`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setItems(data.data);
          setFrontItems(data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCaims();
  }, [update]);

  /**
   * Handle search query
   */
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  /**
   * Filters
   */
  function disambiguateLabel(key: any, value: any) {
    switch (key) {
      case 'taggedWith':
        return `Tagged with ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value: any) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const filters: any = [];

  const appliedFilters = !isEmpty(taggedWith)
    ? [
        {
          key: 'taggedWith',
          label: disambiguateLabel('taggedWith', taggedWith),
          onRemove: () => alert('to do'),
        },
      ]
    : [];

  // Filtering
  useEffect(
    () => {
      const filterItems = () => {
        const filteredItems = items.filter((item: any) => {
          // return item.numero_polizza.toLowerCase().includes(queryValue.toLowerCase());
          return item.customer.toLowerCase().includes(queryValue);
        });
        setFrontItems(filteredItems);
      };
      filterItems();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryValue],
  );

  const handleQueryChange = useCallback(
    (e: string) => {
      setQueryValue(e);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [queryValue],
  );

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={filters}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleClearAll}
    ></Filters>
  );

  /**
   * Handle sort
   */
  useEffect(() => {
    if (sortValue === 'DATE_EVENT_ASC') {
      const tmp = [...frontItems];
      // @ts-ignore
      tmp.sort((a, b) => new Date(a.date_event) - new Date(b.date_event));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_EVENT_DESC') {
      const tmp = [...frontItems];
      // @ts-ignore
      tmp.sort((a, b) => new Date(b.date_event) - new Date(a.date_event));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CREATED_ASC') {
      const tmp = [...frontItems];
      // @ts-ignore
      tmp.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CREATED_DESC') {
      const tmp = [...frontItems];
      // @ts-ignore
      tmp.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CLOSED_ASC') {
      const tmp = [...frontItems];
      // @ts-ignore
      tmp.sort((a, b) => new Date(a.date_closed) - new Date(b.date_closed));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CLOSED_DESC') {
      const tmp = [...frontItems];
      // @ts-ignore
      tmp.sort((a, b) => new Date(b.date_closed) - new Date(a.date_closed));
      setFrontItems(tmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  /**
   * Index table handlers & markup
   */
  const resourceIDResolver = (products: any) => {
    return products._id;
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(frontItems, {
    resourceIDResolver,
  });

  // Row markup
  const rowMarkup = frontItems.map((claim: IClaim, index: any) => {
    // Customer name markup
    const customerMarkup = claim.user ? claim.user.lastname ? `${claim.user.lastname} ${claim.user.name}` : claim.user.name : <Badge>Mancante</Badge>;

    // Claim type markup
    const claimTypeMarkup = claim.accident ? parseClaimType((claim.accident as IAccident)?.accident_type) : <Badge>Mancante</Badge>;

    // Date accident markup
    const dateAccidentMarkup = (claim.accident as IAccident)?.accident_date_time ? (
      parseDateLabel(new Date((claim.accident as IAccident)?.accident_date_time))
    ) : (
      <Badge>Mancante</Badge>
    );

    // Date end markup
    const dateEndMarkup = claim.date_end ? parseDateLabel(new Date(claim.date_end)) : <Badge>Mancante</Badge>;

    return (
      <IndexTable.Row id={claim._id} key={claim._id} selected={selectedResources.includes(claim._id)} position={index}>
        <IndexTable.Cell>
          <Text as="span" fontWeight="semibold">
            <Link url={`/admin/claims/${claim._id}`} removeUnderline monochrome dataPrimaryLink>
              {customerMarkup}
            </Link>
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{claimTypeMarkup}</IndexTable.Cell>
        <IndexTable.Cell>{renderClaimStatusBadge(claim.status)}</IndexTable.Cell>
        <IndexTable.Cell>{dateAccidentMarkup}</IndexTable.Cell>
        <IndexTable.Cell>{parseDateLabel(new Date(claim.date_created))}</IndexTable.Cell>
        <IndexTable.Cell>{dateEndMarkup}</IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  /**
   * Empty state markup
   */
  const emptyList = (
    <EmptyState heading="Gestisci i sinistri" image="https://cdn.shopify.com/shopifycloud/web/assets/v1/e7b58a8b2e612fe6cf6f8c9e53830b70.svg">
      <p>Qua è dove puoi gestire i tuoi sinistri</p>
    </EmptyState>
  );

  /**
   * Pagination markup
   */
  const paginationMarkup =
    items.length > 0 ? (
      <div className={styles.CustomerListFooter}>
        <Pagination
          hasPrevious={!isFirstPage}
          hasNext={!isLastPage}
          // onPrevious={this.handlePreviousPage}
          // onNext={this.handleNextPage}
        />
      </div>
    ) : null;

  /**
   * Markup with items
   */
  useEffect(() => {
    if (items.length > 0) setIsLoading(false);
  }, [items]);

  const customerListMarkup = (
    <LegacyCard>
      <div style={{ padding: '16px', display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            queryPlaceholder={'Filtra clienti'}
            onQueryChange={setQueryValue}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
          />
        </div>
        <div style={{ paddingLeft: '0.4rem' }}>
          <Select
            labelInline
            label="Ordina per"
            options={[
              { label: 'Data aggiunta sinistro (dal più recente)', value: 'DATE_CREATED_DESC' },
              { label: 'Data aggiunta sinistro (dal meno recente)', value: 'DATE_CREATED_ASC' },
              { label: 'Data evento sinistro (dal più recente)', value: 'DATE_EVENT_DESC' },
              { label: 'Data evento sinistro (dal meno recente)', value: 'DATE_EVENT_ASC' },
              { label: 'Data chiusura sinistro (dal più recente)', value: 'DATE_CLOSED_DESC' },
              { label: 'Data chiusura sinistro (dal meno recente)', value: 'DATE_CLOSED_ASC' },
            ]}
            value={sortValue}
            onChange={(selected) => {
              setSortValue(selected);
            }}
          />
        </div>
      </div>
      <IndexTable
        resourceName={resourceName}
        emptyState={emptyList}
        itemCount={frontItems.length}
        selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        hasMoreItems
        loading={isLoading}
        onSelectionChange={handleSelectionChange}
        headings={[
          { title: 'Cliente' },
          { title: 'Tipologia' },
          { title: 'Stato' },
          { title: 'Data evento' },
          { title: 'Data apertura' },
          { title: 'Data chiusura' },
        ]}
        sort
      >
        {rowMarkup}
      </IndexTable>
    </LegacyCard>
  );

  return customerListMarkup;
}
