import { useCallback, useEffect, useRef, useState } from 'react';

import {
  Autocomplete,
  Avatar,
  Banner,
  Button,
  ContextualSaveBar,
  DropZone,
  EmptyState,
  Filters,
  FormLayout,
  Frame,
  Icon,
  Layout,
  Modal,
  Page,
  ResourceItem,
  ResourceList,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  LegacyStack,
  TextContainer,
  TextField,
  Text,
  Thumbnail,
  Toast,
  LegacyCard,
  Badge,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup } from '../../../../components';
import { ChangeReportsModal } from '../components/ChangeReportsModal';

import './OrganizationDetails.scss';
import { getInitials } from '../../../../utils/Common';
import axios, { AxiosError } from 'axios';
import { OrganizationRoles, User } from '../../../../types';
import { useUser } from '../../../../utils/PrivateRoute';
import { useNavigate, useParams } from 'react-router-dom';
import { CirclePlusMinor, NoteMinor, SearchMinor, RefreshMinor, ClipboardMinor } from '@shopify/polaris-icons';

type AutocompleteUserOption = {
  label: string;
  value: string;
  user: User;
};

export function OrganizationDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [update, setUpdate] = useState(false);
  const [active, setActive] = useState(false);
  const [toastCopyActive, setToastCopyActive] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [modalRoleActive, setModalRoleActive] = useState(false);
  const [modalRemoveLogoActive, setModalRemoveLogoActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [removeLogoSpinning, setRemoveLogoSpinning] = useState(false);
  const [error, setError] = useState(false);
  const [pwdError, setPwdError] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toggleToastCopyActive = useCallback(() => setToastCopyActive((toastCopyActive) => !toastCopyActive), []);

  const [items, setItems] = useState<{ user: User; role: string }[]>([]);
  const [frontItems, setFrontItems] = useState<{ user: User; role: string }[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [taggedWith, setTaggedWith] = useState('VIP');
  const [queryValue, setQueryValue] = useState('');
  const [sortValue, setSortValue] = useState('NAME_DESC');

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: boolean) => {
    setMobileNavigationActive(!data);
  };

  // State Modal reports
  const [changeReportsModalActive, setChangeReportsModalActive] = useState(false);
  const handleChangeReportsModal = useCallback(() => setChangeReportsModalActive((changeReportsModalActive) => !changeReportsModalActive), []);

  /**
   * States
   */
  const [name, setName] = useState('');
  const [type, setType] = useState<'broker' | 'affinity'>('broker');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [vat, setVat] = useState('');

  const [fiscalData, setFiscalData] = useState('');
  const [orderSequentialName, setOrderSequentialName] = useState('');

  // Api keys
  const [apiKeyTest, setApiKeyTest] = useState('');
  const [apiKeyLive, setApiKeyLive] = useState('');

  // Referral code
  const [referralCode, setReferralCode] = useState('');

  const [line, setLine] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('IT'); // Value is country.code
  const [province, setProvince] = useState('');
  const [reports, setReports] = useState(0);
  const [reportTypes, setReportTypes] = useState<{ reports_family: number; reports_individual: number }>({ reports_family: 0, reports_individual: 0 });
  const [familyReports, setFamilyReports] = useState(0);
  const [familyUnlimited, setFamilyUnlimited] = useState(false);
  const [individualReports, setIndividualReports] = useState(0);
  const [totalReports, setTotalReports] = useState(0);

  const [memberId, setMemberId] = useState('');
  const [users, setUsers] = useState<{ user: string; role: string }[]>([]);

  // Users
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [deselectedOptions, setDeselectedOptions] = useState<AutocompleteUserOption[]>([]);
  const [options, setOptions] = useState<AutocompleteUserOption[]>([]);

  // Logo
  const [logo, setLogo] = useState<File>();
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/svg'];

  // Default
  const [defaultState, setDefaultState] = useState({
    name: '',
    type: 'broker',
    email: '',
    phone: '',
    vat: '',
    fiscalData: '',
    orderSequentialName: '',
    line: '',
    city: '',
    zip: '',
    country: 'IT',
    province: '',
    logo: {
      key: '',
      title: '',
    },
    reports: '',
    familyUnlimited: false,
    mainOrganization: false,
  });

  /**
   * Role
   */
  const [selectedRole, setSelectedRole] = useState<string>(OrganizationRoles.user);
  const handleSelectRoleChange = useCallback((value: any) => setSelectedRole(value), []);

  const roleOptions = [
    { label: 'Admin', value: OrganizationRoles.admin },
    { label: 'User', value: OrganizationRoles.user },
  ];

  /** Discard */
  const handleDiscard = useCallback(() => {
    setName(defaultState.name);
    setEmail(defaultState.email);
    setIsDirty(false);
  }, [defaultState]);

  /**
   * Data fetching:
   * - fetch organization
   * - fetch users
   */
  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/organizations/${params.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          const { organization } = data.data;
          setDefaultState({
            name: organization.name ?? '',
            type: organization.type ?? 'broker',
            email: organization.email ?? '',
            phone: organization.phone ?? '',
            vat: organization.vat ?? '',
            fiscalData: organization.fiscalData ?? '',
            orderSequentialName: organization.order_sequential_name ?? '',
            line: (organization.address && organization.address.line) ?? '',
            city: (organization.address && organization.address.city) ?? '',
            zip: (organization.address && organization.address.zip) ?? '',
            country: (organization.address && organization.address.country) ?? '',
            province: (organization.address && organization.address.province) ?? '',
            logo: {
              key: (organization.logo && organization.logo.key) ?? '',
              title: (organization.logo && organization.logo.title) ?? '',
            },
            reports: organization.wallet.family_analysis ?? 0,
            familyUnlimited: organization.wallet.family_unlimited,
            mainOrganization: organization.main_organization ?? false,
          });
          setName(organization.name ?? '');
          setType(organization.type ?? 'broker');
          setEmail(organization.email ?? '');
          setPhone(organization.phone ?? '');
          setVat(organization.vat ?? '');
          setFiscalData(organization.fiscal_data ?? '');
          setOrderSequentialName(organization.order_sequential_name ?? '');
          setLine((organization.address && organization.address.line) ?? '');
          setCity((organization.address && organization.address.city) ?? '');
          setZip((organization.address && organization.address.zip) ?? '');
          setCountry((organization.address && organization.address.country) ?? '');
          setProvince((organization.address && organization.address.province) ?? '');
          setReports(organization.wallet.family_analysis ?? 0);
          setFamilyUnlimited(organization.wallet.family_unlimited ?? false);
          setApiKeyTest(organization.api_key_test?.key ?? '');
          setApiKeyLive(organization.api_key_live?.key ?? '');
          setReferralCode(organization.referral_code ?? '');

          setItems(organization.users);
          setFrontItems(organization.users);

          const tmp = [];
          for (const user of organization.users) {
            tmp.push({ user: user.user._id, role: user.role });
          }
          setUsers(tmp);

          const { reports_family, reports_individual } = data.data;
          setFamilyReports(reports_family);
          setIndividualReports(reports_individual);
          setTotalReports(reports_family + reports_individual);

          return organization.type ?? 'broker';
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchUsers = async (org_type: string) => {
      try {
        setIsLoading(true);

        const user_role = org_type === 'broker' ? 'partner' : 'affinity';
        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/users/?role=${user_role}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          const tmp = [];
          for (const user of data.data) {
            tmp.push({
              value: user._id,
              label: user.lastname && user.lastname.length > 0 ? `${user.name} ${user.lastname} - ${user.email}` : `${user.name} - ${user.email}`,
              user: user,
            });
          }
          setDeselectedOptions(tmp);
          setOptions(tmp);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrganization().then((org_type: string) => {
      fetchUsers(org_type);
    });
  }, [params.id, update]);

  /**
   * Add user to organization
   */
  const handleAddUser = useCallback(
    async (userId: string, role: string) => {
      try {
        setButtonSpinning(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL ?? `/api`}/admin/organizations/${params.id}`,
          {
            user: userId,
            role: role,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          },
        );
        const data = response.data;

        if (data.status === 'success') {
          setActive(true);
        } else {
          setError(true);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
        handleDiscard();
        setError(true);
      } finally {
        setButtonSpinning(false);
      }
    },
    [handleDiscard, params.id],
  );

  /**
   * Resource list
   */
  const handleSelectionChange = useCallback((selectedItems: any) => setSelectedItems(selectedItems), []);

  const handleTaggedWithRemove = useCallback(() => setTaggedWith(''), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleTaggedWithRemove]);

  const resourceName = {
    singular: 'utente',
    plural: 'utenti',
  };

  const filters: any[] = [];

  // Filtering
  useEffect(() => {
    const filterItems = () => {
      const filteredItems = items.filter((item: { user: User }) => {
        return user.name.toLowerCase().includes(queryValue.toLowerCase());
      });
      setFrontItems(filteredItems);
    };
    filterItems();
  }, [queryValue]);

  const handleQueryChange = useCallback((e: string) => {
    setQueryValue(e);
  }, []);

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={filters}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleClearAll}
    ></Filters>
  );

  /**
   * Render row
   */
  function renderItem(item: any, _: any, index: number | undefined) {
    const { user: partner, role }: { user: User; role: string } = item;
    const media = <Avatar customer={false} size="medium" initials={`${getInitials(partner.name)}`} name={'_id'} />;
    const url = `/admin/users/${partner._id}`;

    return (
      <ResourceItem
        id={partner._id}
        url={url}
        media={media}
        sortOrder={index}
        accessibilityLabel={`View details for ${name}`}
        shortcutActions={[
          {
            content: 'Modifica ruolo',
            onAction: () => {
              setSelectedRole(role);
              setMemberId(partner._id);
              handleModalRoleChange();
            },
          },
        ]}
      >
        <div className="dataScroll">
          <div>
            <Text as="span" fontWeight="semibold">
              {partner.name} {partner._id === user.id && '(Io)'}
            </Text>
          </div>
          <div>
            <p>
              <Text as="span" color="subdued">
                Email: {partner.email} - Ruolo: {String(role).charAt(0).toUpperCase() + String(role).slice(1)}
              </Text>
            </p>
          </div>
        </div>
      </ResourceItem>
    );
  }

  function resolveItemIds({ id }: any) {
    return id;
  }

  /** Empty list markup */
  const emptyListMarkup = !items.length && (
    <EmptyState heading="Gestisci i membri" image="https://cdn.shopify.com/shopifycloud/web/assets/v1/e7b58a8b2e612fe6cf6f8c9e53830b70.svg">
      <p>Qua è dove puoi gestire le informazioni dei tuoi membri</p>
    </EmptyState>
  );

  /**
   * Remove members
   */
  const handleRemoveMembers = useCallback(async () => {
    try {
      const response = await axios.delete(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/organizations/${params.id}/users`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
          data: {
            users: selectedItems,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        // Remove users from frontItems
        const newFrontItems = frontItems.filter((item) => {
          return !selectedItems.includes(item.user._id);
        });
        setFrontItems(newFrontItems);
        setActive(true);
      } else {
        setError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      setError(true);
    }
  }, [frontItems, params.id, selectedItems]);

  const promotedBulkActions = [
    {
      content: 'Rimuovi utenti',
      onAction: handleRemoveMembers,
    },
  ];

  /**
   * Save data
   */
  const handleSave = useCallback(async () => {
    try {
      setError(false);
      setButtonSpinning(true);

      // Create form data
      const formData = new FormData();
      if (logo) {
        formData.append('document', logo, logo?.name);
      }
      formData.append('name', name);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('vat', vat);
      formData.append('fiscal_data', fiscalData);
      formData.append('order_sequential_name', orderSequentialName);
      formData.append('line', line);
      formData.append('city', city);
      formData.append('zip', zip);

      const response = await axios.put(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/organizations/${params.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        setActive(true);
        setIsDirty(false);
        setUpdate(!update);
      } else {
        handleDiscard();
        setError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      handleDiscard();
      setError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [params.id, name, email, phone, vat, fiscalData, orderSequentialName, logo, line, city, zip, handleDiscard, update]);

  /**
   * Handle create API Keys
   */
  const handleCreateApiKeys = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL ?? `/api`}/admin/organizations/${params.id}/api-keys`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        setUpdate(!update);
      } else {
        setError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      setError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [params.id]);

  /**
   * Handle create referral code
   */
  const handleCreateReferralCode = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL ?? `/api`}/admin/organizations/${params.id}/referral-code`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        setUpdate(!update);
      } else {
        setError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      setError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [params.id]);

  /**
   * Handle copy API Key Live
   */
  const handleCopyApiKeyLive = useCallback(() => {
    navigator.clipboard.writeText(apiKeyLive);
    toggleToastCopyActive();
  }, [apiKeyLive]);

  /**
   * Handle copy API Key Test
   */
  const handleCopyApiKeyTest = useCallback(() => {
    navigator.clipboard.writeText(apiKeyTest);
    toggleToastCopyActive();
  }, [apiKeyTest]);

  /**
   * Handle copy referral code
   */
  const handleCopyReferralCode = useCallback(() => {
    navigator.clipboard.writeText(referralCode);
    toggleToastCopyActive();
  }, [referralCode]);

  /**
   * Change role in organization
   */
  const handleModalRoleChange = useCallback(async () => {
    setModalRoleActive(!modalRoleActive);
  }, [modalRoleActive]);

  const handleUpdateRole = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await axios.put(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/organizations/${params.id}/role`,
        {
          user: memberId,
          role: selectedRole,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        // Update user role in frontItems
        const newItems = [...frontItems];
        const index = newItems.findIndex((item) => item.user._id === memberId);
        if (index !== -1) {
          newItems[index].role = selectedRole;
        }
        setFrontItems(newItems);

        setActive(true);
        handleModalRoleChange();
      } else {
        setError(true);
        handleModalRoleChange();
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      handleDiscard();
      handleModalRoleChange();
      setError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [frontItems, handleDiscard, handleModalRoleChange, params.id, memberId, selectedRole]);

  /**
   * Remove logo
   */
  const handleModalRemoveLogoChange = useCallback(() => {
    setModalRemoveLogoActive(!modalRemoveLogoActive);
  }, [modalRemoveLogoActive]);

  const handleRemoveLogo = useCallback(async () => {
    try {
      setRemoveLogoSpinning(true);
      const response = await axios.delete((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/organizations/${params.id}/logo`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;

      if (data.status === 'success') {
        setLogo(undefined);
        setActive(true);
      } else {
        setError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      setError(true);
    } finally {
      setRemoveLogoSpinning(false);
      handleModalRemoveLogoChange();
    }
  }, [user.organization, handleModalRemoveLogoChange]);

  /**
   * Delete organization
   */
  const handleModalChange = useCallback(async () => {
    setModalActive(!modalActive);
  }, [modalActive]);

  const handleDelete = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await axios.delete((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/organizations/${params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;

      if (data.status === 'success') {
        // Redirect
        navigate('/admin/organizations');
      } else {
        setError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      handleDiscard();
      setError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [handleDiscard, history, params.id]);

  /**
   * Handlers
   */
  const handleNameChange = useCallback((e: string) => {
    setName(e);
    setIsDirty(true);
  }, []);

  // Email
  const handleEmailChange = useCallback((e: string) => {
    setEmail(e);
    setIsDirty(true);
  }, []);

  // Vat
  const handleVatChange = useCallback((e: any) => {
    setVat(e);
    setIsDirty(true);
  }, []);

  // Fiscal data
  const handleFiscalDataChange = useCallback((e: any) => {
    setFiscalData(e);
    setIsDirty(true);
  }, []);

  // Order sequential name
  const handleOrderSequentialNameChange = useCallback((e: any) => {
    setOrderSequentialName(e);
    setIsDirty(true);
  }, []);

  // Phone
  const handlePhoneChange = useCallback((e: string) => {
    // Allow only numbers
    if (e.match(/^[0-9]*$/)) {
      setPhone(e);
    }
    setIsDirty(true);
  }, []);

  // Line
  const handleLineChange = useCallback((e: string) => {
    setLine(e);
  }, []);

  // City
  const handleCityChange = useCallback((e: string) => {
    setCity(e);
  }, []);

  // Zip
  const handleZipChange = useCallback((e: string) => {
    // Allow only numbers
    if (e.match(/^[0-9]*$/)) {
      setZip(e);
    }

    // Allow only 5 numbers
    if (e.length > 5) {
      setZip(e.substring(0, 5));
    }
  }, []);

  // Country
  const handleCountryChange = useCallback((e: string) => {
    setCountry(e);
  }, []);

  /**
   * Save bar
   */
  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Modifiche non salvate"
      saveAction={{
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
    />
  ) : null;

  /**
   * Autocomplete Controls
   */
  const updateText = useCallback(
    (value: any) => {
      setInputValue(value);

      if (value === '') {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = deselectedOptions.filter((option) => {
        // @ts-ignore
        return option.label.match(filterRegex);
      });
      setOptions(resultOptions);
    },
    [deselectedOptions],
  );

  const updateSelection = useCallback(
    (selected: any) => {
      const selectedValue = selected.map((selectedItem: any) => {
        const matchedOption = options.find((option) => {
          // @ts-ignore
          return option.value.match(selectedItem);
        });
        // @ts-ignore
        return matchedOption;
      });
      setSelectedOptions(selected);
      setInputValue(selectedValue[0].label);

      // Add user to organization
      handleAddUser(selectedValue[0].value, OrganizationRoles.user)
        .then(() => {
          setFrontItems([
            ...frontItems,
            {
              user: selectedValue[0].user,
              role: 'user',
            },
          ]);

          // Add selected users to the list of users
          setUsers([...users, { user: selectedValue[0].value, role: 'user' }]);
        })
        .catch(() => {
          setError(true);
        });
    },
    [frontItems, handleAddUser, options, users],
  );

  const customerTextField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={updateText}
      label="Ricerca utente"
      value={inputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca"
    />
  );

  /**
   * Organization drop zone logo
   */
  const handleDropZoneDrop = useCallback((_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
    setLogo(acceptedFiles[0]);
    setIsDirty(true);
  }, []);

  const fileInDb = (
    <div style={{ padding: '0' }}>
      <LegacyStack alignment="center">
        <Thumbnail size="large" alt={defaultState.logo.title} source={process.env.REACT_APP_BLOB_IMAGES_URL + defaultState.logo.key} />
        <div>{defaultState.logo.title}</div>
      </LegacyStack>
    </div>
  );

  const fileUpload = !logo && defaultState.logo.key ? fileInDb : !logo ? <DropZone.FileUpload /> : null;

  const uploadedFile = logo && (
    <div style={{ padding: '0' }}>
      <LegacyStack alignment="center">
        <Thumbnail size="large" alt={logo.name} source={NoteMinor as any} />
        <div>{logo.name}</div>
      </LegacyStack>
    </div>
  );

  const logoDropZoneMarkup = (
    <DropZone onDrop={handleDropZoneDrop} allowMultiple={false}>
      {uploadedFile}
      {fileUpload}
    </DropZone>
  );

  /**
   * Banner
   */
  const bannerMarkup = error && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const bannerWrongPwdMarkup = pwdError && (
    <Layout.Section>
      <Banner title="Password errata" status="critical" onDismiss={() => setPwdError(false)}>
        <p>Si è pregati di ricontrollare la password.</p>
      </Banner>
    </Layout.Section>
  );

  const modalMarkup = (
    <Modal
      open={modalActive}
      onClose={handleModalChange}
      title="Rimozione organizzazione"
      primaryAction={{
        content: 'Continua',
        onAction: handleDelete,
        destructive: true,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Cancella',
          onAction: handleModalChange,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>Si è sicuri di voler procedere con la rimozione dell'organizzazione?</p>
          <p>Una volta che si è proceduto non è possibile tornare indietro. Sarà necessario creare una nuova organizzazione.</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );

  const removeOrganizationButton = (
    <Button destructive disabled={isDirty} onClick={handleModalChange}>
      Elimina organizzazione
    </Button>
  );

  /** Modal to change user role */
  const modalRoleMarkup = (
    <Modal
      open={modalRoleActive}
      onClose={handleModalRoleChange}
      title="Aggiorna ruolo"
      primaryAction={{
        content: 'Continua',
        onAction: handleUpdateRole,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Cancella',
          onAction: handleModalRoleChange,
        },
      ]}
    >
      <Modal.Section>
        <Select label="Ruolo" options={roleOptions} onChange={handleSelectRoleChange} value={selectedRole} />
      </Modal.Section>
    </Modal>
  );

  /**
   * Remove logo modal
   */
  const removeLogoAction = defaultState.logo.key
    ? [
        {
          content: 'Rimuovi logo',
          onAction: handleModalRemoveLogoChange,
        },
      ]
    : undefined;

  const modalRemoveLogoMarkup = (
    <Modal
      open={modalRemoveLogoActive}
      onClose={handleModalRemoveLogoChange}
      title="Rimuovi logo"
      primaryAction={{
        content: 'Continua',
        onAction: handleRemoveLogo,
        loading: removeLogoSpinning,
        destructive: true,
      }}
      secondaryActions={[
        {
          content: 'Cancella',
          onAction: handleModalRemoveLogoChange,
        },
      ]}
    >
      <Modal.Section>
        <p>Si è sicuri di voler procedere con la rimozione?</p>
        <p>Una volta eliminato non è più possibile tornare indietro.</p>
      </Modal.Section>
    </Modal>
  );

  /**
   * Organization logo title
   */
  const organizationLogoTitle = (
    <LegacyStack alignment="center">
      <Avatar
        customer={false}
        size="medium"
        source={process.env.REACT_APP_BLOB_IMAGES_URL && defaultState.logo.key ? `${process.env.REACT_APP_BLOB_IMAGES_URL}${defaultState.logo.key}` : ''}
        name={'_id'}
      />
      <Badge status={type === 'broker' ? 'attention' : 'info'}>{type === 'broker' ? 'Intermediario' : 'Affinity'}</Badge>
    </LegacyStack>
  );

  /**
   * Checks if family reports are unlimited
   */
  const unlimitedReports = familyUnlimited === true ? 'Illimitati' : 'Limitati';

  /**
   * Reports count markup
   */
  const reportsCountMarkup = !defaultState.mainOrganization && (
    <LegacyCard sectioned>
      <LegacyStack vertical>
        <Text variant="headingMd" as="h2">
          <span className="cardTitle">Report effettuati</span>
        </Text>
        <Text variant="heading2xl" as="p">
          {totalReports}
        </Text>
        <Text variant="headingMd" as="p">
          Family reports: {familyReports}
        </Text>
        <Text variant="headingMd" as="p">
          Business reports: {individualReports}
        </Text>
      </LegacyStack>
    </LegacyCard>
  );

  /**
   * API keys markup
   */
  const apiKeysMarkup = type === 'affinity' && (
    <Layout.AnnotatedSection title="Chiavi API" description="Le chiavi API che il partner può utilizzare per le integrazioni">
      <LegacyCard
        sectioned
        title="Chiavi API"
        actions={[
          {
            content: 'Crea chiavi API',
            onAction: handleCreateApiKeys,
          },
        ]}
      >
        <FormLayout>
          <FormLayout.Group>
            <TextField
              autoComplete="on"
              type="text"
              label="Chiave API Live"
              value={apiKeyLive}
              onChange={() => void 0}
              disabled
              suffix={
                <div style={{ display: 'flex' }}>
                  <Button onClick={handleCopyApiKeyLive} icon={ClipboardMinor as any} plain />
                </div>
              }
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              autoComplete="on"
              type="text"
              label="Chiave API Test"
              value={apiKeyTest}
              onChange={() => void 0}
              disabled
              suffix={
                <div style={{ display: 'flex' }}>
                  <Button onClick={handleCopyApiKeyTest} icon={ClipboardMinor as any} plain />
                </div>
              }
            />
          </FormLayout.Group>
        </FormLayout>
      </LegacyCard>
    </Layout.AnnotatedSection>
  );

  /**
   * Referral code markup
   */
  const referralCodeMarkup = type === 'affinity' && (
    <Layout.AnnotatedSection title="Referral code" description="Il referral code che il partner può utilizzare per le integrazioni">
      <LegacyCard
        sectioned
        title="Referral code"
        actions={[
          {
            content: 'Crea referral code',
            onAction: handleCreateReferralCode,
            disabled: referralCode.length > 0,
          },
        ]}
      >
        <FormLayout>
          <FormLayout.Group>
            <TextField
              autoComplete="on"
              type="text"
              label="Codice"
              value={referralCode}
              onChange={() => void 0}
              disabled
              suffix={
                <div style={{ display: 'flex' }}>
                  <Button onClick={handleCopyReferralCode} icon={ClipboardMinor as any} plain />
                </div>
              }
            />
          </FormLayout.Group>
        </FormLayout>
      </LegacyCard>
    </Layout.AnnotatedSection>
  );

  /**
   * Page markup
   */
  const actualPageMarkup = (
    <Page
      title={`${defaultState.name}`}
      titleMetadata={organizationLogoTitle}
      backAction={{ content: 'Organizzazioni', url: '/admin/organizations' }}
      secondaryActions={[
        {
          content: 'Gestisci prodotti',
          accessibilityLabel: 'Gestisci i prodotti',
          // onAction: () => history.push(`/admin/organizations/${params.id}/products`),
          url: `/admin/organizations/${params.id}/products`,
        },
        {
          content: 'Modifica reports',
          accessibilityLabel: 'Modifica quantita di reports',
          icon: RefreshMinor as any,
          onAction: () => handleChangeReportsModal(),
        },
      ]}
    >
      <Layout>
        {/* Banner */}
        {bannerMarkup}
        {bannerWrongPwdMarkup}
        {/* Total Reports */}
        {type === 'broker' && (
          <Layout.AnnotatedSection title="Reports" description="Numero di reports disponibili e di reports effettuati.">
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Text variant="headingMd" as="h2">
                  <span className="cardTitle">Report totali</span>
                  <span> </span>
                  <Badge>{unlimitedReports}</Badge>
                </Text>
                <Text variant="heading2xl" as="p">
                  {familyUnlimited ? <>&#8734;</> : reports}
                </Text>
              </LegacyStack>
            </LegacyCard>

            {/* Reports count markup */}
            {reportsCountMarkup}
          </Layout.AnnotatedSection>
        )}

        {/* Api keys*/}
        {apiKeysMarkup}

        {/* Referral code */}
        {referralCodeMarkup}

        {/* Panoramica organizazzione */}
        <Layout.AnnotatedSection
          title="Dettagli organizzazione"
          description="Un'organizzazione rappresenta un partner (broker, agenzia, azienda, altro) che ha un accordo di collaborazione."
        >
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <TextField autoComplete="on" type="text" label="Nome" value={name} onChange={handleNameChange} />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField autoComplete="on" type="text" label="P.IVA" value={vat} onChange={handleVatChange} />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="email"
                  label="Email"
                  value={email}
                  onChange={handleEmailChange}
                  helpText="Verrà utilizzato per le comunicazioni all'azienda."
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="tel"
                  label="Numero di telefono"
                  value={phone}
                  onChange={handlePhoneChange}
                  helpText="Verrà utilizzato per le comunicazioni all'azienda."
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
        {/* Fiscal Data */}
        <Layout.AnnotatedSection
          title="Dati fiscali"
          description="Le informazioni fiscali che dovranno essere stampate nei preventivi e nelle fatture delle polizze collettive."
        >
          <LegacyCard sectioned>
            <TextField label="Dati fiscali" value={fiscalData} onChange={handleFiscalDataChange} multiline={4} autoComplete="off" />
          </LegacyCard>
        </Layout.AnnotatedSection>
        {/* Logo */}
        <Layout.AnnotatedSection title="Logo" description="Il logo dell'organizzazione. Caricare una nuova immagine per sostituire quella attuale.">
          <LegacyCard sectioned actions={removeLogoAction}>
            {logoDropZoneMarkup}
          </LegacyCard>
        </Layout.AnnotatedSection>
        {/* Order sequential name */}
        <Layout.AnnotatedSection
          title="Progressivo ordini"
          description="Il codice progressivo, relativo all'organizzazione, che sarà utilizzato per identificare i preventivi e le fatture."
        >
          <LegacyCard sectioned>
            <TextField label="Progressivo" value={orderSequentialName} onChange={handleOrderSequentialNameChange} autoComplete="off" />
          </LegacyCard>
        </Layout.AnnotatedSection>
        {/* Address */}
        <Layout.AnnotatedSection title="Indirizzo" description="L'indirizzo principale di questa organizzazione.">
          <LegacyCard sectioned>
            <FormLayout>
              {/* <FormLayout.Group>
                <Select label="Stato" options={countryOptions} onChange={handleCountryChange} value={country} />
              </FormLayout.Group> */}
              <FormLayout.Group>
                <TextField autoComplete="on" type="text" label="Indirizzo" value={line} onChange={handleLineChange} />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField autoComplete="on" type="text" label="CAP" value={zip} onChange={handleZipChange} />
                <TextField autoComplete="on" type="text" label="Città" value={city} onChange={handleCityChange} />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
        {/* Organization users */}
        <Layout.AnnotatedSection title="Membri organizzazione">
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <Autocomplete
                  options={options}
                  selected={selectedOptions}
                  onSelect={updateSelection}
                  textField={customerTextField}
                  loading={isLoading}
                  actionBefore={{
                    accessibilityLabel: 'Action label',
                    content: 'Aggiungi utente',
                    ellipsis: true,
                    icon: CirclePlusMinor as any,
                    onAction: () => {
                      navigate('/admin/users/new');
                    },
                  }}
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
          <LegacyCard>
            <ResourceList
              resourceName={resourceName}
              items={frontItems}
              renderItem={renderItem}
              emptyState={emptyListMarkup}
              selectedItems={selectedItems}
              onSelectionChange={handleSelectionChange}
              resolveItemId={resolveItemIds}
              filterControl={filterControl}
              sortValue={sortValue}
              sortOptions={[
                { label: 'Data aggiunta utente (dal più recente)', value: 'DATE_CREATED_DESC' },
                { label: 'Data aggiunta utente (dal meno recente)', value: 'DATE_CREATED_ASC' },
              ]}
              onSortChange={(selected) => {
                setSortValue(selected);
              }}
              loading={isLoading}
              promotedBulkActions={promotedBulkActions}
            />
          </LegacyCard>
        </Layout.AnnotatedSection>
        <Layout.Section />
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  const toastMarkup = active ? <Toast content="I dati sono stati aggiornati" onDismiss={toggleActive} /> : null;

  const toastCopyMarkup = toastCopyActive ? <Toast content="Copiato!" onDismiss={toggleToastCopyActive} /> : null;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {contextualSaveBarMarkup}
      {pageMarkup}
      {modalMarkup}
      {modalRoleMarkup}
      {modalRemoveLogoMarkup}
      {toastMarkup}
      {toastCopyMarkup}
      {ChangeReportsModal({
        active: changeReportsModalActive,
        reports: reports,
        unlimited: familyUnlimited,
        onChangeReports: setReports,
        onChangeUnlimited: setFamilyUnlimited,
        onToastActive: setActive,
        onClose: handleChangeReportsModal,
      })}
    </Frame>
  );
}
