import { LegacyCard, EmptyState, Filters, Text, IndexTable, Select, useIndexResourceState, FilterInterface, ComplexAction } from '@shopify/polaris';
import { useCallback, useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';

type Job = {
  _id: string;
  label: string;
};

interface JobListProps {
  /** The success toast state */
  setActive: any;
  /** The error toast state */
  setSaveError: any;
  /** The counter error state */
  setCounterError: any;
  /** The user props */
  user: any;
  /** The update state */
  update: boolean;
  /** The update handler */
  setUpdate: any;
  /** Toggle deleted */
  toggleDeleted: any;
}

export function JobList({ setActive, setSaveError, setCounterError, user, update, setUpdate, toggleDeleted }: JobListProps) {
  const [queryValue, setQueryValue] = useState('');
  const [taggedWith, setTaggedWith] = useState(null);
  const [sortValue, setSortValue] = useState('ALPHABETICAL_ASC');
  const [items, setItems] = useState<Job[]>([]);
  const [frontItems, setFrontItems] = useState<Job[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const resourceName = {
    singular: 'professione',
    plural: 'professioni',
  };

  /**
   * Data fetching
   */
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/jobs`, {
          headers: {
            'Cache-Control': 'no-cache',
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          console.log('here');
          setItems(data.data);
          setFrontItems(data.data);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchJobs().then(() => setIsLoading(false));
  }, [update]);

  /**
   * Handle search query
   */
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  /**
   * Filters
   */
  function disambiguateLabel(key: string, value: null) {
    switch (key) {
      case 'taggedWith':
        return `Tagged with ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value: string | any[] | null) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const filters: FilterInterface[] = [];

  const appliedFilters = !isEmpty(taggedWith)
    ? [
        {
          key: 'taggedWith',
          label: disambiguateLabel('taggedWith', taggedWith),
          // @ts-ignore
          onRemove: handleTaggedWithRemove,
        },
      ]
    : [];

  // Filtering
  useEffect(() => {
    const filterItems = () => {
      const filteredItems = items.filter((item: Job) => {
        return item.label.toLowerCase().includes(queryValue ? queryValue.toLowerCase() : '');
      });
      setFrontItems(filteredItems);
    };
    filterItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  /**
   * Handle sort
   */
  useEffect(() => {
    if (sortValue === 'ALPHABETICAL_ASC') {
      const tmp = [...frontItems];
      tmp.sort((a, b) => a.label.localeCompare(b.label));
      setFrontItems(tmp);
    } else if (sortValue === 'ALPHABETICAL_DESC') {
      const tmp = [...frontItems];
      tmp.sort((a, b) => b.label.localeCompare(a.label));
      setFrontItems(tmp);
    }
  }, [sortValue]);

  /**
   * Index table handlers & markup
   */
  const resourceIDResolver = (job: { _id: any }) => {
    return job._id;
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange, removeSelectedResources } = useIndexResourceState(frontItems, {
    resourceIDResolver,
  });

  /**
   * Handle delete
   */
  const handleDelete = useCallback(async () => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/jobs`, {
        data: {
          job_ids: selectedResources,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });

      const data = response.data;

      if (data.status === 'success') {
        setUpdate(!update);
        removeSelectedResources(selectedResources);
        toggleDeleted();
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      const status = axiosError.response?.status;
      console.log(error);

      setCounterError(true);
    }
  }, [selectedResources, setActive, setCounterError, setSaveError, update, removeSelectedResources, setUpdate, toggleDeleted]);

  const promotedBulkActions = [
    {
      content: 'Elimina professioni',
      onAction: handleDelete,
    },
  ];

  /**
   * Row markup
   */
  const rowMarkup = frontItems.map((item: Job, index) => {
    const { _id: id, label } = item;

    return (
      <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
        <IndexTable.Cell>
          <Text as="span" fontWeight="semibold">
            {label}
          </Text>
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  /**
   * Empty list markup
   */
  let emptyStateMarkup: ComplexAction | undefined = undefined;
  if (user.role === 'admin') {
    emptyStateMarkup = {};
    emptyStateMarkup.content = 'Aggiungi professione';
    emptyStateMarkup.onAction = () => {
      alert('Aggiungi professione');
    };
  }

  const emptyList = (
    <EmptyState
      heading="Gestisci le professioni"
      image="https://cdn.shopify.com/shopifycloud/web/assets/v1/67d1bd2ad29c4adc50fb195375f31fcb1674823604794398778c01f6c185d702.svg"
      action={emptyStateMarkup}
    >
      <p>Qua è dove puoi gestire le professioni</p>
    </EmptyState>
  );

  const jobListMarkup = (
    <LegacyCard>
      <div style={{ padding: '16px', display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Filters
            queryValue={queryValue}
            filters={filters}
            // @ts-ignore
            appliedFilters={appliedFilters}
            queryPlaceholder={'Filtra professioni'}
            onQueryChange={setQueryValue}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
          />
        </div>
        <div style={{ paddingLeft: '0.4rem' }}>
          <Select
            labelInline
            label="Ordina per"
            options={[
              { label: 'Ordine crescente', value: 'ALPHABETICAL_ASC' },
              { label: 'Ordine decrescente', value: 'ALPHABETICAL_DESC' },
            ]}
            value={sortValue}
            onChange={(selected) => {
              setSortValue(selected);
            }}
          />
        </div>
      </div>
      <IndexTable
        resourceName={resourceName}
        emptyState={emptyList}
        loading={isLoading}
        itemCount={frontItems.length}
        selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        promotedBulkActions={promotedBulkActions}
        onSelectionChange={handleSelectionChange}
        headings={[{ title: 'Nome' }]}
      >
        {rowMarkup}
      </IndexTable>
    </LegacyCard>
  );

  return jobListMarkup;
}
