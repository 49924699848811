import { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Banner,
  Button,
  DatePicker,
  DropZone,
  FormLayout,
  HorizontalStack,
  Icon,
  Modal,
  Select,
  Tag,
  TextContainer,
  TextField,
} from '@shopify/polaris';
import axios, { AxiosError } from 'axios';
import React from 'react';
import { SearchMinor } from '@shopify/polaris-icons';
import { Variant } from '../../types';

interface NewCustomerPolicyProps {
  modalActive: boolean;
  customerId?: string;
  handleClose(): void;
  setNewPolicyOrQuoteSuccess: (success: boolean) => void;
  setIsPolicy: (success: boolean) => void;
  setUpdated?: (state: boolean) => void;
}

type Label = 'type' | 'date_start' | 'date_end';

interface Item {
  label: Label;
  value: string;
}

type AutocompleteProductOption = {
  label: string;
  value: string;
  productId: string;
};

export const NewCustomerPolicy = ({ modalActive, handleClose, customerId, setNewPolicyOrQuoteSuccess, setIsPolicy, setUpdated }: NewCustomerPolicyProps) => {
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [error, setError] = useState(false);
  const [fileError, setFileError] = useState(false);

  /**
   * Upload
   */
  const [file, setFile] = useState<File | null>(null);

  const typeOptions = [
    { label: 'Preventivo', value: 'quote' },
    { label: 'Polizza', value: 'policy' },
  ];
  const [selectedType, setSelectedType] = useState<'quote' | 'policy' | null>(null);
  const [confidence, setConfidence] = useState<number>(0);
  const [scanResults, setScanResults] = useState<Item[]>([]);

  const handleSelectedType = useCallback((value: string) => {
    setSelectedType(value as 'quote' | 'policy');

    /**
     * TODO:
     * If the admin changes the selected type, the other fields should be reset
     */
  }, []);

  /** Dates */
  const [monthCreated, setMonthCreated] = useState(new Date().getMonth());
  const [yearCreated, setYearCreated] = useState(new Date().getFullYear());
  const [dateCreatedSelection, setDateCreatedSelection] = useState(false);
  const [selectedDatesCreated, setSelectedDatesCreated] = useState({ start: new Date(), end: new Date() });

  const [monthExpired, setMonthExpired] = useState(new Date().getMonth());
  const [yearExpired, setYearExpired] = useState(new Date().getFullYear() + 1);
  const [dateExpiredSelection, setDateExpiredSelection] = useState(false);
  const [selectedDatesExpired, setSelectedDatesExpired] = useState({
    start: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()),
    end: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()),
  });

  /**
   * Start date handlers
   */
  const handleMonthCreatedChange = useCallback(
    (month: number, year: number) => {
      setMonthCreated(month);
      setYearCreated(year);
    },
    [{ monthCreated, yearCreated }],
  );
  const handleDateCreatedSelection = useCallback(() => {
    setDateCreatedSelection(!dateCreatedSelection);
  }, [dateCreatedSelection]);
  const handleSelectedDatesCreated = useCallback(
    (e: any) => {
      setSelectedDatesCreated(e);

      // Set expired date to 1 year after created date
      setSelectedDatesExpired({
        start: new Date(e.start.getFullYear() + 1, e.start.getMonth(), e.start.getDate()),
        end: new Date(e.start.getFullYear() + 1, e.start.getMonth(), e.start.getDate()),
      });

      if (dateCreatedSelection) setDateCreatedSelection(false);
    },
    [dateCreatedSelection],
  );

  /**
   * Expired Handlers
   */
  const handleMonthExpiredChange = useCallback(
    (month: number, year: number) => {
      setMonthExpired(month);
      setYearExpired(year);
    },
    [{ monthExpired, yearExpired }],
  );
  const handleDateExpiredSelection = useCallback(() => {
    setDateExpiredSelection(!dateExpiredSelection);
  }, [dateExpiredSelection]);
  const handleSelectedDatesExpired = useCallback(
    (e: any) => {
      setSelectedDatesExpired(e);
      if (dateExpiredSelection) setDateExpiredSelection(false);
    },
    [dateExpiredSelection],
  );

  // Payments
  const [amount, setAmount] = useState('');
  const [monthPayment, setMonthPayment] = useState(new Date().getMonth());
  const [yearPayment, setYearPayment] = useState(new Date().getFullYear());
  const [datePaymentSelection, setDatePaymentSelection] = useState(false);
  const [selectedDatesPayment, setSelectedDatesPayment] = useState({
    start: new Date(),
    end: new Date(),
  });

  const [selectedPaymentType, setSelectedPaymentType] = useState<'cash' | 'transfer' | 'card' | 'other'>('transfer');

  const paymentOptions = [
    { label: 'Bonifico', value: 'transfer', key: 1 },
    { label: 'Contanti', value: 'cash', key: 2 },
    { label: 'Bancomat/Carta', value: 'card', key: 3 },
    { label: 'Altro', value: 'other', key: 4 },
  ];

  /**
   * Payment date handlers
   */
  const handleMonthPaymentChange = useCallback(
    (month: number, year: number) => {
      setMonthPayment(month);
      setYearPayment(year);
    },
    [{ monthPayment, yearPayment }],
  );
  const handleDatePaymentSelection = useCallback(() => {
    setDatePaymentSelection(true);
  }, []);
  const handleSelectedDatesPayment = useCallback(
    (e: any) => {
      setSelectedDatesPayment(e);
      if (datePaymentSelection) setDatePaymentSelection(false);
    },
    [datePaymentSelection],
  );

  const handleAmountChange = useCallback((value: any) => {
    setAmount(value);
  }, []);

  const handleSelectPaymentChange = useCallback((value: any) => {
    setSelectedPaymentType(value);
  }, []);

  /** Drop zone */
  const handleDropZoneDrop = useCallback((_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => setFile(acceptedFiles[0]), []);

  const fileUpload = !file && <DropZone.FileUpload />;
  const uploadedFile = file && (
    <HorizontalStack gap={'5'} blockAlign="center">
      <div>{file.name}</div>
    </HorizontalStack>
  );

  const uploadMarkup = (
    <Modal.Section>
      <FormLayout>
        <Banner title="Crea un nuovo preventivo o polizza" status="warning">
          <p>
            Attraverso questa funzionalità si può caricare un documento per creare un nuovo preventivo o polizza. Non è possibile allegare documenti a ordini
            già esistenti tramite questa funzione.
          </p>
        </Banner>
        <FormLayout.Group>
          <DropZone allowMultiple={false} onDrop={handleDropZoneDrop} label="Carica qua il documento" error={fileError}>
            {uploadedFile}
            {fileUpload}
          </DropZone>
        </FormLayout.Group>
        {/* <FormLayout.Group>
        <Select label="Tipologia di documento" options={typeOptions} onChange={(e) => setSelectedType(e)} value={selectedType} />
      </FormLayout.Group> */}
      </FormLayout>
    </Modal.Section>
  );

  function parseDate(dateString: string) {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  }

  // Reset form function
  const resetForm = () => {
    setFile(null);
    setSelectedType(null);
    setConfidence(0);
    setScanResults([]);
    setMonthCreated(new Date().getMonth());
    setYearCreated(new Date().getFullYear());
    setDateCreatedSelection(false);
    setSelectedDatesCreated({ start: new Date(), end: new Date() });
    setMonthExpired(new Date().getMonth());
    setYearExpired(new Date().getFullYear() + 1);
    setDateExpiredSelection(false);
    setSelectedDatesExpired({
      start: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()),
      end: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()),
    });
    setAmount('');
    setSelectedPaymentType('transfer');
    setSelectedDatesPayment({ start: new Date(), end: new Date() });
    setProductInputValue('');
    setSelectedProductOptions([]);
    setLineItemsVariant([]);
    setLineItems([]);
    setEmptyFields({ lineItems: false, customer: false, amount: false });
  };

  const handleUpload = useCallback(async () => {
    try {
      setError(false);
      setButtonSpinning(true);

      // Create form data
      const formData = new FormData();
      if (file) {
        formData.append('document', file);
      }

      const response = await axios.post(`${process.env.REACT_APP_API_URL ?? '/api'}/quotes/scan/minified`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;

      if (data.status === 'success' && data.data.scan_results && data.data.scan_results.length !== 0) {
        // Set selected type based on the item containing value type
        const items: Item[] = data.data.scan_results;
        const result = items.reduce<Record<Label, string>>((acc, item) => {
          acc[item.label] = item.value;
          return acc;
        }, {} as Record<Label, string>);

        setSelectedType(result.type as 'quote' | 'policy');
        setConfidence(parseFloat(data.data.confidence));
        setScanResults(items);

        // Set dates
        setSelectedDatesCreated({
          start: result.date_start ? parseDate(result.date_start) : new Date(),
          end: result.date_start ? parseDate(result.date_start) : new Date(),
        });
        setSelectedDatesExpired({
          start: result.date_end ? parseDate(result.date_end) : new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()),
          end: result.date_end ? parseDate(result.date_end) : new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()),
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      const status = axiosError.response?.status || 500;
      const data: any = axiosError.response?.data;

      setError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [file]);

  /**
   * Product selection
   */
  const [deselectedProductOptions, setDeselectedProductOptions] = useState<AutocompleteProductOption[]>([]);
  const [selectedProductOptions, setSelectedProductOptions] = useState([]);
  const [productInputValue, setProductInputValue] = useState('');
  const [productOptions, setProductOptions] = useState(deselectedProductOptions);
  const [productLoading, setProductLoading] = useState(false);
  const [variants, setVariants] = useState<Variant[]>([]);
  const [lineItemsVariant, setLineItemsVariant] = useState<Variant[]>([]);
  const [lineItems, setLineItems] = useState<string[]>([]);

  const [emptyFields, setEmptyFields] = useState({
    lineItems: false,
    customer: false,
    amount: false,
  });

  /**
   * Data fetching:
   * - fetch products
   */
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setProductLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/products`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          const variantsTmp: any[] = [];
          const productOptions = [];
          for (const product of data.data) {
            for (const variant of product.variants) {
              productOptions.push({
                value: variant._id,
                label: `${product.name} - ${variant.name}`,
                productId: product._id,
              });
              variantsTmp.push(variant);
            }
          }

          setDeselectedProductOptions(productOptions);
          setProductOptions(productOptions);

          setVariants(variantsTmp);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setProductLoading(false);
      }
    };
    fetchProducts();
  }, []);

  /**
   * Products autocomplete
   */
  const updateProductText = useCallback(
    (value: string) => {
      setProductInputValue(value);

      if (!productLoading) {
        setProductLoading(true);
      }

      setTimeout(() => {
        if (value === '') {
          setProductOptions(deselectedProductOptions);
          setProductLoading(false);
          return;
        }
        const filterRegex = new RegExp(value, 'i');
        const resultOptions = deselectedProductOptions.filter((option: any) => option.label.match(filterRegex));
        setProductOptions(resultOptions);
        setProductLoading(false);
      }, 300);
    },
    [deselectedProductOptions, productLoading],
  );

  const updateProductSelection = useCallback(
    (selected: any) => {
      // Check if emptyFields is true
      if (emptyFields.lineItems) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, lineItems: false }));
      }

      const selectedText = selected.map((selectedItem: any) => {
        const matchedOption = productOptions.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption && matchedOption.label;
      });
      setSelectedProductOptions(selected);
      setProductInputValue(selectedText[0]);

      // Add variant to lineItems
      const variantId = selected[0];
      const newLineItemsVariant = lineItemsVariant.slice();
      const newLineItems = lineItems.slice();
      // Find variant with variantId from variants array
      const variant = variants.find((variant: any) => variant._id === variantId);
      // Check if variant is already present in previous array
      const index = newLineItemsVariant.findIndex((item: any) => item._id === variantId);

      if (index === -1 && variant !== undefined) {
        newLineItemsVariant.push(variant);
        setLineItemsVariant(newLineItemsVariant);
        newLineItems.push(variantId);
        setLineItems(newLineItems);
      }
    },
    [emptyFields.lineItems, productOptions, lineItemsVariant, lineItems, variants],
  );

  const productTextField = (
    <Autocomplete.TextField
      onChange={updateProductText}
      label={null}
      value={productInputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca un prodotto"
      autoComplete="off"
      error={emptyFields.lineItems && 'Per favore seleziona un prodotto'}
    />
  );

  const emptyProductState = (
    <React.Fragment>
      <div style={{ textAlign: 'center' }}>
        <TextContainer>Nessun prodotto trovato</TextContainer>
      </div>
    </React.Fragment>
  );

  const productMarkup = (
    <Modal.Section>
      <Autocomplete
        options={productOptions}
        selected={selectedProductOptions}
        onSelect={updateProductSelection}
        loading={productLoading}
        textField={productTextField}
        emptyState={emptyProductState}
      />
      {/* Add a tag containing the product if it has been selected */}
      {productInputValue && (
        <div style={{ marginTop: '10px' }}>
          <Tag
            onRemove={() => {
              setProductInputValue('');
              setSelectedProductOptions([]);
              setLineItemsVariant([]);
              setLineItems([]);
            }}
          >
            {productInputValue}
          </Tag>
        </div>
      )}
    </Modal.Section>
  );

  /**
   * Type confirmation
   */
  const typeConfirmationMarkup = selectedType && (
    <Modal.Section>
      <Select options={typeOptions} value={selectedType} onChange={handleSelectedType} label={`Tipologia documento caricato (confidenza ${confidence})`} />
    </Modal.Section>
  );

  /**
   * Policy Markup
   */
  const policyMarkup = (
    <Modal.Section>
      <FormLayout>
        {/* Date start */}
        <FormLayout.Group title="Decorrenza">
          <TextField
            autoComplete="on"
            type="text"
            disabled={true}
            labelHidden={true}
            label="Data di Inizio"
            value={selectedDatesCreated.start.toLocaleDateString('it-IT', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })}
          />
          <Button onClick={handleDateCreatedSelection}>Seleziona Data di Decorrenza Polizza</Button>
        </FormLayout.Group>
        <FormLayout.Group>
          {dateCreatedSelection && (
            <DatePicker
              month={monthCreated}
              year={yearCreated}
              onChange={handleSelectedDatesCreated}
              onMonthChange={handleMonthCreatedChange}
              selected={selectedDatesCreated}
              allowRange={false}
              weekStartsOn={1}
            />
          )}
        </FormLayout.Group>
        {/* Date end */}
        <FormLayout.Group>
          <TextField
            autoComplete="on"
            type="text"
            disabled={true}
            labelHidden={true}
            label="Data di Scadenza"
            value={selectedDatesExpired.start.toLocaleDateString('it-IT', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })}
          />
          <Button onClick={handleDateExpiredSelection}>Seleziona Data di Scadenza Polizza</Button>
        </FormLayout.Group>
        <FormLayout.Group>
          {dateExpiredSelection && (
            <DatePicker
              month={monthExpired}
              year={yearExpired}
              onChange={handleSelectedDatesExpired}
              onMonthChange={handleMonthExpiredChange}
              selected={selectedDatesExpired}
              allowRange={false}
              weekStartsOn={1}
            />
          )}
        </FormLayout.Group>
      </FormLayout>
    </Modal.Section>
  );

  /**
   * Payment Markup
   */
  const paymentMarkup = (
    <Modal.Section>
      <FormLayout>
        <FormLayout.Group>
          <TextField
            autoComplete="on"
            suffix="€"
            type="text"
            label="Importo"
            value={amount}
            onChange={handleAmountChange}
            error={emptyFields.amount && "Per favore inserisci l'importo"}
          />
          <Select label="Metodo di pagamento" options={paymentOptions} onChange={handleSelectPaymentChange} value={selectedPaymentType} />
        </FormLayout.Group>
        <FormLayout.Group>
          <Button onClick={handleDatePaymentSelection}>Seleziona Data di Pagamento Polizza</Button>
          <TextField
            autoComplete="on"
            type="text"
            disabled={true}
            labelHidden={true}
            label="Data pagamento"
            value={selectedDatesPayment.start.toLocaleDateString('it', { day: '2-digit', month: '2-digit', year: 'numeric' })}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          {datePaymentSelection && (
            <DatePicker
              month={monthPayment}
              year={yearPayment}
              onChange={handleSelectedDatesPayment}
              onMonthChange={handleMonthPaymentChange}
              selected={selectedDatesPayment}
              allowRange={false}
              weekStartsOn={1}
            />
          )}
        </FormLayout.Group>
      </FormLayout>
    </Modal.Section>
  );

  /**
   * Handle save
   */
  const handleSave = useCallback(async () => {
    try {
      setButtonSpinning(true);
      setError(false);
      setFileError(false);

      // Check if all required fields are filled
      if (lineItems.length === 0) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, lineItems: true }));
        return;
      }

      // Check if amount is filled only for policy
      if (selectedType === 'policy' && amount === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, amount: true }));
        return;
      }

      if (!selectedType) {
        return;
      }

      // Create form data
      const formData = new FormData();
      if (file) {
        formData.append('document', file);
      }

      // Add customer id to form data
      if (customerId) {
        formData.append('customer', customerId);
      }

      // Add line items
      formData.append('line_items', JSON.stringify(lineItems));

      // Add dates
      formData.append('date_start', selectedDatesCreated.start.toISOString());
      formData.append('date_end', selectedDatesExpired.start.toISOString());

      // Add payment
      formData.append('amount', amount);
      formData.append('payment_date', selectedDatesPayment.start.toISOString());
      formData.append('payment_method', selectedPaymentType);

      formData.append('type', selectedType);
      formData.append('scan_results', JSON.stringify(scanResults));

      const response = await axios.post(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/orders/add-policy-or-quote`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;

      if (data.status === 'success') {
        handleClose();
        setNewPolicyOrQuoteSuccess(true);
        setIsPolicy(selectedType === 'policy');
        setUpdated && setUpdated(true);
        resetForm();
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      const status = axiosError.response?.status || 500;
      const data: any = axiosError.response?.data;

      setError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [file, lineItems, selectedDatesCreated, selectedDatesExpired, amount, selectedDatesPayment, selectedPaymentType, selectedType]);

  /**
   * Primary action markup based on step
   */
  const primaryActionMarkup = () => {
    switch (selectedType) {
      case null:
        return {
          content: 'Carica',
          onAction: handleUpload,
          disabled: !file,
          loading: buttonSpinning,
        };
      case 'quote':
        return {
          content: 'Salva',
          onAction: handleSave,
          // disabled: !isDirty,
          loading: buttonSpinning,
        };
      case 'policy':
        return {
          content: 'Salva',
          onAction: handleSave,
          // disabled: !isDirty,
          loading: buttonSpinning,
        };
      default:
        return {
          content: 'Carica',
          onAction: () => {
            setButtonSpinning(true);
            setTimeout(() => {
              setButtonSpinning(false);
            }, 1000);
          },
          disabled: !file,
          loading: buttonSpinning,
        };
    }
  };

  /** Error markup */
  const errorMarkup = error && (
    <Modal.Section>
      <Banner title="Si è verificato un errore nel caricamento del file" status="critical" onDismiss={() => setError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Modal.Section>
  );

  /** File error markup */
  const fileErrorMarkup = fileError && (
    <Modal.Section>
      <Banner title="È obbligatorio allegare il file" status="critical" onDismiss={() => setFileError(false)} />
    </Modal.Section>
  );

  return (
    <Modal
      open={modalActive}
      onClose={() => {
        setFile(null);
        resetForm();
        handleClose();
      }}
      title="Crea preventivo/polizza"
      primaryAction={primaryActionMarkup()}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: () => {
            setFile(null);
            handleClose();
          },
        },
      ]}
    >
      {errorMarkup}
      {fileErrorMarkup}

      {uploadMarkup}
      {selectedType && typeConfirmationMarkup}
      {selectedType && productMarkup}

      {selectedType === 'policy' && policyMarkup}
      {selectedType === 'policy' && paymentMarkup}
    </Modal>
  );
};
