export enum UserRoles {
  admin = 'admin',
  customer = 'customer',
  partner = 'partner',
  constultant = 'consultant',
  affinity = 'affinity',
}

export type User = {
  _id: string;
  email: string;
  name: string;
  lastname?: string;
  phone?: string;
  privacy?: boolean;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  customer?: string;
  date_created: Date;
  date_updated: Date;
  role: string;
  verified: boolean;
  /** The user language */
  language?: 'it' | 'en' | 'fr' | 'de' | 'es';
  /** The user status */
  status?: 'active' | 'draft';
};
