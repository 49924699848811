import React, { useCallback, useRef, useState } from 'react';

import {
  Button,
  LegacyCard,
  Frame,
  Layout,
  Loading,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  Toast,
  Banner,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup, ClaimList } from '../../../components';

import { useUser } from '../../../utils/PrivateRoute';

export function ClaimAll() {
  const { user } = useUser();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = () => {
    setMobileNavigationActive((data) => !data);
  };

  const [active, setActive] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const loadingMarkup = isLoading ? <Loading /> : null;

  /**
   * Error markups & toast
   */
  const toastMarkup = active ? <Toast content="I dati sono stati aggiornati con successo." onDismiss={toggleActive} /> : null;

  const saveErrorMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  // ---- Page markup ----
  const actualPageMarkup = (
    <Page
      fullWidth
      title="Sinistri"
      primaryAction={
        <Button primary url="/claims/new">
          Aggiungi sinistro
        </Button>
      }
    >
      <Layout>
        {/* Banner */}
        {saveErrorMarkup}
        {/* List */}
        <Layout.Section>
          <ClaimList />
        </Layout.Section>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </Frame>
  );
}
