/**
 * Modal to create a new quotation
 */
import { Banner, Button, Card, FormLayout, IconSource, LegacyStack, Modal, Select, Text, TextField } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { OrderMetadata, PricingRule, QuotationField, QuotationMetadata } from '../../types';
import { DeleteMinor, PlusMinor } from '@shopify/polaris-icons';
import { isInputElement } from 'react-router-dom/dist/dom';

/**
 * Render quotation field markup
 */
type Props = {
  metadataField: QuotationMetadata | undefined;
  name: string;
  type: string;
  resultModifierType: 'fixed_amount' | 'percentage' | 'pricing_table' | 'none';
  fieldValue: string;
  /** The quotation fields values: can be populated with the select options or other quotation fields */
  values?: Array<{ label: string; value: string }> | QuotationField[] | QuotationMetadata[] | QuotationMetadata;
  /** The quotation field api field name */
  api_field?: string;
  handleSubtotal: (value: string, resultModifierType: 'fixed_amount' | 'percentage' | 'pricing_table' | 'none', fieldValue: string) => void;
  handleMetadata: (
    value: string,
    name: string,
    type: 'text' | 'number' | 'currency' | 'array' | 'date' | 'select',
    api_field?: string,
    itemIndex?: number,
  ) => void;
  addNewArrayItem: (arrayFieldName: string) => void;
  itemIndex?: number;
  groupMarkupProps: Array<{ key: number }>;
  setGroupMarkup: React.Dispatch<React.SetStateAction<Array<{ key: number }>>>;
};
const QuotationFieldComponent = ({
  metadataField,
  name,
  type,
  resultModifierType,
  fieldValue,
  values,
  api_field,
  handleSubtotal,
  handleMetadata,
  addNewArrayItem,
  itemIndex,
  groupMarkupProps,
  setGroupMarkup,
}: Props) => {
  const [value, setValue] = useState(metadataField?.value || '');
  const [quotationFieldsValues, setQuotationFieldsValues] = useState(values ?? []);

  const handleCurrencyChange = useCallback((e: any) => {
    // Check if the price has 2 or more commas and remove the last one
    if (e.split(',').length > 2) {
      e = e.replace(/,(?=[^,]*$)/, '');
    }

    // Check if the price has 3 or more numbers after the comma and remove the last one
    if (e.split(',')[1] && e.split(',')[1].length > 2) {
      e = e.replace(/.$/, '');
    }

    // Check if there are letters or special characters and remove them
    if (e.match(/[^0-9,]/g)) {
      e = e.replace(/[^0-9,]/g, '');
    }

    // Add dot as thousand separator
    e = e.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    setValue(e);
    handleSubtotal(e.replace('.', ''), resultModifierType, fieldValue);
    handleMetadata(e.replace('.', '').replace(',', '.'), name, 'currency', api_field, itemIndex);
  }, []);

  const handleValueChange = useCallback((e: any) => {
    // Check if the price has 2 or more commas and remove the last one
    if (e.split(',').length > 2) {
      e = e.replace(/,(?=[^,]*$)/, '');
    }

    // Check if the price has 3 or more numbers after the comma and remove the last one
    if (e.split(',')[1] && e.split(',')[1].length > 2) {
      e = e.replace(/.$/, '');
    }

    // Check if there are letters or special characters and remove them
    if (e.match(/[^0-9,]/g)) {
      e = e.replace(/[^0-9,]/g, '');
    }

    // Add dot as thousand separator
    e = e.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    setValue(e);
    handleSubtotal(e.replace('.', ''), resultModifierType, fieldValue);
    handleMetadata(e.replace('.', '').replace(',', '.'), name, 'number', api_field, itemIndex);
  }, []);

  const handleTextChange = useCallback((e: any) => {
    setValue(e);
    handleMetadata(e, name, 'text', api_field, itemIndex);
  }, []);

  /**
   * Render quotation modal markup
   */
  const renderArrayField = useCallback(
    (value?: string | number | QuotationMetadata[] | QuotationMetadata[][]) => {
      const addQuotationFieldsValuesMarkup = () => {
        setGroupMarkup((prevMarkup) => [...prevMarkup, { key: Date.now() }]);
        addNewArrayItem(name);
      };

      const removeElementFromGroupMarkup = (index: number) => {
        setGroupMarkup((prevMarkup) => prevMarkup.filter((_, i) => i !== index));
      };

      if (groupMarkupProps.length === 0) {
        addQuotationFieldsValuesMarkup();
      }

      return (
        <LegacyStack vertical spacing="loose">
          {groupMarkupProps.map((group, index) => (
            <LegacyStack.Item key={group.key}>
              <Card>
                <FormLayout>
                  <Text variant="headingMd" as="h3">{`${name} ${index + 1}`}</Text>
                  {(quotationFieldsValues as QuotationField[]).map((field: QuotationField, fieldIndex: number) => {
                    // Inizializza un valore temporaneo
                    const tmpValue = value as QuotationMetadata[];

                    let currentValue: any = field; // Default to the field itself

                    if (Array.isArray(tmpValue)) {
                      const foundIndex = tmpValue.findIndex((val) => val.label === field.name);

                      // Se troviamo il valore, assegnalo a currentValue
                      if (foundIndex > -1) {
                        currentValue = tmpValue[foundIndex];
                        // Se vuoi rimuoverlo dall'array, fallo in modo non distruttivo
                        tmpValue.splice(foundIndex, 1); // Evita di modificare direttamente tmpValue
                      }
                    }

                    return (
                      <React.Fragment key={fieldIndex}>
                        <QuotationFieldComponent
                          metadataField={currentValue}
                          name={currentValue.name ?? currentValue.label}
                          type={currentValue.type}
                          resultModifierType={field.result_modifier_type || 'none'}
                          fieldValue={field.result_modifier_value || '0'}
                          // @ts-ignore
                          values={Array.isArray(value) ? value : undefined}
                          api_field={field.api_field_name}
                          handleSubtotal={handleSubtotal}
                          handleMetadata={(val, fieldName, fieldType, fieldApiField) => handleMetadata(val, fieldName, fieldType, fieldApiField, index)}
                          addNewArrayItem={addNewArrayItem}
                          itemIndex={index}
                          groupMarkupProps={groupMarkupProps}
                          setGroupMarkup={setGroupMarkup}
                        />
                      </React.Fragment>
                    );
                  })}
                  <Button onClick={() => removeElementFromGroupMarkup(index)} icon={DeleteMinor as IconSource} destructive>
                    Rimuovi {name}
                  </Button>
                </FormLayout>
              </Card>
            </LegacyStack.Item>
          ))}
          <LegacyStack.Item>
            <Button onClick={addQuotationFieldsValuesMarkup} icon={PlusMinor as IconSource}>
              Aggiungi {name}
            </Button>
          </LegacyStack.Item>
        </LegacyStack>
      );
    },
    [quotationFieldsValues, groupMarkupProps, handleSubtotal, handleMetadata, name, addNewArrayItem],
  );

  /**
   * Markup for different field types
   */
  const renderMarkup = (type: string) => {
    switch (type) {
      case 'currency':
        return (
          <TextField
            autoComplete="off"
            label={name}
            type="currency"
            placeholder="0,00"
            value={value.toString()}
            onChange={handleCurrencyChange}
            min={0}
            step={0.01}
            prefix="EUR"
            suffix="€"
          />
        );
      case 'number':
      case 'percentage':
        return <TextField autoComplete="off" label={name} type="number" value={value.toString()} onChange={handleValueChange} />;
      case 'text':
        return (
          <TextField
            autoComplete="off"
            label={name}
            type="text"
            helpText="Verrà visualizzato nel preventivo"
            value={value.toString()}
            onChange={handleTextChange}
          />
        );
      case 'array':
        // Check if the quotation fields values are populated
        return renderArrayField(value);
      case 'date':
        return <TextField autoComplete="off" label={name} type="date" value={value.toString()} onChange={handleTextChange} />;
      case 'select':
        return (
          <Select
            label={name}
            options={quotationFieldsValues as Array<{ label: string; value: string }>}
            onChange={(e) => {
              setValue(e);
              handleMetadata(e, name, 'select', api_field, itemIndex);
            }}
            value={value.toString()}
          />
        );
      default:
        return (
          <TextField
            autoComplete="off"
            label={name}
            type="currency"
            placeholder="0,00"
            value={value.toString()}
            onChange={handleCurrencyChange}
            min={0}
            step={0.01}
            prefix="EUR"
            suffix="€"
          />
        );
    }
  };

  return renderMarkup(type);
};

export const QuotationModal = (
  variantId: string,
  quotationFields: QuotationField[] | null,
  orderMetadata: OrderMetadata | undefined,
  pricing_rules: PricingRule[] | undefined,
  active: boolean,
  setActive: any,
  onSave: any,
  onMetadata: any,
) => {
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [existError, setExistError] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [quotationMetadata, setQuotationMetadata] = useState<QuotationMetadata[]>([]);
  const [groupMarkup, setGroupMarkup] = useState<Array<{ key: number }>>([]);

  // Set metadata
  useEffect(() => {
    const tmp: QuotationMetadata[] = [];

    quotationFields?.forEach((field) => {
      if (field.type === 'text') {
        tmp.push({
          label: field.name,
          value: '',
          type: 'text',
          api_field: field.api_field_name ?? field.name,
        });
      } else if (field.type === 'percentage' || field.type === 'number') {
        tmp.push({
          label: field.name,
          value: 0,
          type: 'number',
          api_field: field.api_field_name ?? field.name,
        });
      } else if (field.type === 'currency') {
        tmp.push({
          label: field.name,
          value: 0,
          type: 'currency',
          api_field: field.api_field_name ?? field.name,
        });
      } else if (field.type === 'array') {
        let value: QuotationMetadata[] = [];
        if (field.values && field.values.length > 0) {
          value =
            (field.values as QuotationField[]).map((value): QuotationMetadata => {
              return {
                label: value.name,
                value: '',
                type: value.type as 'number' | 'text' | 'currency',
                api_field: value.api_field_name ?? value.name,
              };
            }) ?? [];
        }

        tmp.push({
          label: field.name,
          type: 'array',
          value: value,
          api_field: field.api_field_name ?? field.name,
        });
      } else if (field.type === 'date') {
        tmp.push({
          label: field.name,
          value: '',
          type: 'date',
          api_field: field.api_field_name ?? field.name,
        });
      } else if (field.type === 'select') {
        tmp.push({
          label: field.name,
          value: '',
          type: 'select',
          api_field: field.api_field_name ?? field.name,
        });
      }
    });

    setQuotationMetadata(tmp);
  }, [quotationFields, variantId]);

  /**
   * Handle subtotal
   * @param value
   * @param resultModifierType
   * @param fieldValue
   * @returns
   */
  const handleSubtotal = (value: string, resultModifierType: 'fixed_amount' | 'percentage' | 'pricing_table' | 'none', fieldValue: string) => {
    // Check if the price has a comma and replace it with a dot
    if (value.includes(',')) {
      value = value.replace(',', '.');
    }

    // Check if the price is a number
    if (!isNaN(parseFloat(value))) {
      let newValue = parseFloat(value);

      // Check if the price is a percentage
      if (resultModifierType === 'percentage') {
        newValue = (newValue / 100) * parseFloat(fieldValue.includes(',') ? fieldValue.replace(',', '.') : fieldValue);
      }

      // Check if the price is a fixed amount
      if (resultModifierType === 'fixed_amount') {
        newValue = newValue;
      }

      setSubtotal(subtotal + newValue);

      // Pricing rules
      if (pricing_rules) {
        pricing_rules.forEach((rule) => {
          if (rule.name === 'minimum_price') {
            if (rule.type === 'percentage') {
              setSubtotal(((subtotal + newValue) / 100) * Number(rule.value));
            } else if (rule.type === 'fixed_amount' && subtotal + newValue < Number(rule.value)) {
              setSubtotal(Number(rule.value));
            }
          }
        });
      }
    }
  };

  /**
   * Handle metadata
   */
  const handleMetadata = useCallback(
    (value: string, name: string, type: 'text' | 'number' | 'currency' | 'array' | 'date' | 'select', api_field?: string, itemIndex?: number) => {
      setQuotationMetadata((prevMetadata: QuotationMetadata[]) => {
        return prevMetadata.map((item): QuotationMetadata => {
          if (item.type === 'array' && Array.isArray(item.value)) {
            let updatedArrayValue = [...item.value] as QuotationMetadata[];
            const slicedItemApiField = api_field?.split('.')[0];
            let itemLength = 0;
            if (quotationFields) {
              const arrayField = quotationFields.filter((field) => field.api_field_name === slicedItemApiField)[0];
              itemLength = arrayField.values ? arrayField.values.length : 0;
            }

            if (itemIndex !== undefined) {
              const firstItemOfSubArray = itemIndex * itemLength;

              const subArray = updatedArrayValue.slice(firstItemOfSubArray, firstItemOfSubArray + itemLength);

              const updatedSubArray = subArray.map((field) => {
                if (field.label === name || field.api_field === api_field) {
                  return {
                    ...field,
                    value: value,
                    type: type,
                  };
                }
                return field;
              });
              updatedArrayValue.splice(firstItemOfSubArray, itemLength, ...updatedSubArray);
            } else {
              // Add new item
              const newItem = ((quotationFields?.find((field) => field.api_field_name === slicedItemApiField)?.values as QuotationField[]) || []).map(
                (field): QuotationMetadata => ({
                  label: field.name,
                  value: field.name === name ? value : '',
                  type: field.type as 'text' | 'number' | 'currency',
                  api_field: field.api_field_name,
                }),
              );
              updatedArrayValue = [...updatedArrayValue, ...newItem];
            }

            return { ...item, value: updatedArrayValue };
          } else if (item.label === name || item.api_field === api_field) {
            return {
              ...item,
              value: value,
              type: type,
            };
          }

          return item;
        });
      });
    },
    [quotationFields],
  );

  /**
   * Add new array item
   */
  const addNewArrayItem = useCallback(
    (arrayFieldName: string) => {
      setQuotationMetadata((prevMetadata: QuotationMetadata[]) => {
        return prevMetadata.map((item): QuotationMetadata => {
          if (item.type === 'array' && Array.isArray(item.value) && item.label === arrayFieldName) {
            // Find the corresponding field in quotationFields
            const arrayField = quotationFields?.find((field) => field.name === arrayFieldName);

            // Create a new item based on the field's structure
            const newItem =
              (arrayField?.values as QuotationField[])?.map((fieldValue): any => {
                return {
                  label: fieldValue.name,
                  value: '',
                  type: fieldValue.type as 'text' | 'number' | 'currency',
                  api_field: fieldValue.api_field_name,
                };
              }) || [];

            // Add the new item to the existing array
            return { ...item, value: [...item.value, ...newItem] } as QuotationMetadata;
          }
          return item;
        });
      });
    },
    [quotationFields],
  );
  /**
   * Render quotation fields
   */

  const renderQuotationFields = () => {
    if (quotationFields) {
      return quotationFields.map((field: QuotationField, index: number) => {
        return (
          <FormLayout.Group key={index}>
            <QuotationFieldComponent
              metadataField={orderMetadata?.fields[index]}
              name={field.name}
              type={field.type}
              resultModifierType={field.result_modifier_type || 'none'}
              fieldValue={field.result_modifier_value || '0'}
              values={field.values}
              api_field={field.api_field_name}
              handleSubtotal={handleSubtotal}
              handleMetadata={handleMetadata}
              addNewArrayItem={addNewArrayItem}
              itemIndex={index}
              groupMarkupProps={groupMarkup}
              setGroupMarkup={setGroupMarkup}
            />
          </FormLayout.Group>
        );
      });
    }
  };

  /**
   * Handle quotation creation
   */
  const handleSave = useCallback(() => {
    setButtonSpinning(true);
    onSave(subtotal);

    // Add quotation metadata
    const tmp = [...quotationMetadata];

    tmp.push({
      label: 'quotation',
      value: subtotal,
      type: 'currency',
      api_field: 'quotation',
    });

    // Check if the order metadata is already set and update it
    if (orderMetadata) {
      orderMetadata.fields = tmp;
    } else {
      orderMetadata = {
        product: variantId,
        fields: tmp,
      };
    }

    onMetadata(orderMetadata);
    setSubtotal(0);
    setButtonSpinning(false);
    setActive(false);
  }, [subtotal, quotationMetadata, orderMetadata, variantId]);

  /**
   * Error markups & toast
   */
  const saveErrorMarkup = saveError && (
    <Modal.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Modal.Section>
  );

  const existErrorMarkup = existError && (
    <Modal.Section>
      <Banner title="Esiste già un cliente associato a questo codice fiscale" status="critical" onDismiss={() => setExistError(false)}>
        <p>Si è pregati di controllare il codice fiscale se si desidera proseguire.</p>
      </Banner>
    </Modal.Section>
  );

  const modalMarkup = (
    <Modal
      open={active}
      onClose={() => setActive(!active)}
      title="Crea un nuovo preventivo"
      primaryAction={{
        content: 'Salva',
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: () => setActive(!active),
        },
      ]}
    >
      {/* Banner */}
      {saveErrorMarkup}
      {existErrorMarkup}
      <Modal.Section>
        <FormLayout>{renderQuotationFields()}</FormLayout>
      </Modal.Section>
    </Modal>
  );

  return modalMarkup;
};
