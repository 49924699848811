import { LegacyCard, EmptyState, Filters, ResourceItem, ResourceList, Text } from '@shopify/polaris';
import React, { useCallback, useState, useEffect } from 'react';
import { Order } from '../../types';

import { parseDateLabel } from '../../utils/Common';

import './DeadlineList.scss';

export function DeadlineList({ deadlines, frontItems, isLoading, handleFrontItems, handleFrontItemsSort }: any) {
  const [queryValue, setQueryValue] = useState('');
  const [sortValue, setSortValue] = useState('DATE_SCADENZA_DESC');

  const resourceName = {
    singular: 'scadenza',
    plural: 'scadenze',
  };

  const filters: any = [];

  /**
   * Handle search query
   */
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  // Filtering
  useEffect(() => {
    const filterItems = () => {
      const filteredItems = deadlines.filter((item: any) => {
        return item.customer.toLowerCase().includes(queryValue.toLowerCase());
      });
      handleFrontItems(filteredItems);
    };
    filterItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  const handleQueryChange = useCallback((e: string) => {
    setQueryValue(e);
  }, []);

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={filters}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleClearAll}
    ></Filters>
  );

  /**
   * Handle sort
   */
  useEffect(() => {
    if (sortValue === 'DATE_SCADENZA_ASC') handleFrontItemsSort(1);
    else if (sortValue === 'DATE_SCADENZA_DESC') handleFrontItemsSort(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  /**
   * Single resource
   */
  function renderItem(item: Order, _: any, index: number | undefined) {
    const { _id: id, date_end } = item;
    const url = `/orders/${id}`;

    const productName =
      item.line_items.length > 1
        ? `${item.line_items[0].product.name} ${item.line_items[0].name} + ${item.line_items.length - 1} altri`
        : item.line_items.length === 1
        ? `${item.line_items[0].product.name} - ${item.line_items[0].name}`
        : null;

    return (
      <ResourceItem id={id} url={url} sortOrder={index} accessibilityLabel={`View details for ${productName}`}>
        <h3>
          <Text as="span" fontWeight="semibold">
            Polizza: {productName}
          </Text>
        </h3>
        <div>
          Cliente: {item.user?.name ?? ''} - Scadenza: {date_end !== undefined && parseDateLabel(new Date(date_end))}
        </div>
      </ResourceItem>
    );
  }

  function resolveItemIds({ id }: any) {
    return id;
  }

  const emptyList = (
    <EmptyState heading="Scadenze" image="https://cdn.shopify.com/shopifycloud/web/assets/v1/e7b58a8b2e612fe6cf6f8c9e53830b70.svg">
      <p>Non sono presenti scadenze</p>
    </EmptyState>
  );

  /**
   * Markup with items
   */
  const deadlineListMarkup = (
    <LegacyCard>
      <ResourceList
        emptyState={emptyList}
        resourceName={resourceName}
        items={frontItems}
        renderItem={renderItem}
        resolveItemId={resolveItemIds}
        filterControl={filterControl}
        sortValue={sortValue}
        sortOptions={[
          { label: 'Data scadenza crescente', value: 'DATE_SCADENZA_ASC' },
          { label: 'Data scadenza decrescente', value: 'DATE_SCADENZA_DESC' },
        ]}
        onSortChange={(e) => setSortValue(e)}
        loading={isLoading}
      />
    </LegacyCard>
  );

  return deadlineListMarkup;
}
