import { Avatar, LegacyCard, EmptyState, Filters, ResourceItem, ResourceList, Text, Pagination } from '@shopify/polaris';
import axios, { AxiosError } from 'axios';
import React, { useCallback, useState, useEffect } from 'react';
import { Organization } from '../../types';
import { getInitials } from '../../utils/Common';

import styles from './OrganizationList.module.css';

export function OrganizationList({ user }: any) {
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [taggedWith, setTaggedWith] = useState('VIP');
  const [queryValue, setQueryValue] = useState('');
  const [sortValue, setSortValue] = useState('DATE_CREATED_DESC');
  const [items, setItems] = useState([]);
  const [frontItems, setFrontItems] = useState([]);
  const [total, setTotal] = useState(-1);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleTaggedWithChange = useCallback((value: any) => setTaggedWith(value), []);
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(''), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleTaggedWithRemove]);

  const resourceName = {
    singular: 'organizzazione',
    plural: 'organizzazioni',
  };

  /**
   * Data fetching
   */
  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/organizations`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setItems(data.data);
          setFrontItems(data.data);
          setTotal(data.data.length);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.error(axiosError.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrganizations();
  }, []);

  const filters: any[] = [];

  // Filtering
  useEffect(() => {
    const filterItems = () => {
      const filteredItems = items.filter((item: Organization) => {
        return item.name.toLowerCase().includes(queryValue.toLowerCase());
      });
      setFrontItems(filteredItems);
    };
    filterItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  const handleQueryChange = useCallback((e: any) => {
    setQueryValue(e);
  }, []);

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={filters}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleClearAll}
    ></Filters>
  );

  /**
   * Handle sort
   */
  useEffect(() => {
    if (sortValue === 'DATE_CREATED_DESC') {
      const tmp = [...items];
      // @ts-ignore
      tmp.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CREATED_ASC') {
      const tmp = [...items];
      // @ts-ignore
      tmp.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
      setFrontItems(tmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  /**
   * Render row
   */
  function renderItem(item: Organization, id: any, index: number | undefined) {
    const { _id, name } = item;
    const media = (
      <Avatar
        customer={false}
        size="medium"
        source={process.env.REACT_APP_BLOB_IMAGES_URL && item.logo?.key ? `${process.env.REACT_APP_BLOB_IMAGES_URL}${item.logo?.key}` : ''}
        initials={`${getInitials(item.name)}`}
        name={'_id'}
      />
    );
    const url = `/admin/organizations/${_id}`;

    return (
      <ResourceItem id={_id} url={url} media={media} sortOrder={index} accessibilityLabel={`View details for ${name}`}>
        <div className={styles.dataScroll}>
          <div>
            <Text as="span" fontWeight="semibold">
              {item.name}
            </Text>
          </div>
          <div>
            <p>
              <Text as="span" color="subdued">
                Email: {String(item.email)}
              </Text>
            </p>
          </div>
          <div>
            <p>
              <Text as="span" color="subdued">
                Reports disponibili: {item.wallet?.family_analysis}
              </Text>
            </p>
          </div>
        </div>
      </ResourceItem>
    );
  }

  function resolveItemIds({ id }: any) {
    return id;
  }

  /**
   * Empty list markup
   */
  const emptyListMarkup = !items.length && (
    <EmptyState heading="Gestisci le organizzazioni" image="https://cdn.shopify.com/shopifycloud/web/assets/v1/e7b58a8b2e612fe6cf6f8c9e53830b70.svg">
      <p>Qua è dove puoi gestire le informazioni delle organizzazioni.</p>
    </EmptyState>
  );

  /**
   * Pagination markup
   */
  const paginationMarkup =
    items.length > 0 ? (
      <div className={styles.CustomerListFooter}>
        <Pagination
          hasPrevious={!isFirstPage}
          hasNext={!isLastPage}
          // onPrevious={this.handlePreviousPage}
          // onNext={this.handleNextPage}
        />
      </div>
    ) : null;

  /**
   * Markup with items
   */
  const customerListMarkup = (
    <LegacyCard>
      <ResourceList
        resourceName={resourceName}
        items={frontItems}
        renderItem={renderItem}
        emptyState={emptyListMarkup}
        selectedItems={selectedItems}
        // onSelectionChange={setSelectedItems}
        resolveItemId={resolveItemIds}
        filterControl={filterControl}
        sortValue={sortValue}
        sortOptions={[
          { label: 'Data aggiunta organizzazione (dal più recente)', value: 'DATE_CREATED_DESC' },
          { label: 'Data aggiunta organizzazione (dal meno recente)', value: 'DATE_CREATED_ASC' },
        ]}
        onSortChange={(selected) => {
          setSortValue(selected);
        }}
        totalItemsCount={total}
        loading={isLoading}
      />
      {paginationMarkup}
    </LegacyCard>
  );

  return customerListMarkup;
}
