import { useCallback, useEffect, useRef, useState } from 'react';
import {
  LegacyCard,
  ContextualSaveBar,
  Frame,
  Layout,
  Loading,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  LegacyStack,
  TextContainer,
  Text,
  Toast,
  Banner,
  FormLayout,
  TextField,
  DropZone,
  Thumbnail,
  Select,
  Modal,
  Button,
  OptionList,
} from '@shopify/polaris';
import { TopBarMarkup, NavigationMarkup } from '../../../../components';
import './CompaniesDetails.scss';
import { useUser } from '../../../../utils/PrivateRoute';
import { useParams, useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { Company, CompanyFile, CompanyFileTypes } from '../../../../types';
import { parseCompanyFileType, renderCompanyTypeBadge } from '../../../../utils/Common';

type FileOption = {
  label: string;
  value: any;
};

export function CompaniesDetails() {
  const { user } = useUser();
  const params = useParams();
  const navigate = useNavigate();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = () => {
    setMobileNavigationActive((data) => !data);
  };

  const [update, setUpdate] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [conflictError, setConflictError] = useState(false);

  const [company, setCompany] = useState<Company>({
    name: '',
    type: 'company',
    referent: {
      name: '',
      lastname: '',
      email: '',
      phone: '',
    },
    billing: {
      email: '',
      day: 1,
    },
    platform_link: '',
    phone: '',
    logo: undefined,
  });

  const companyTypes = [
    { label: 'Compagnia', value: 'company' },
    { label: 'Broker/Agenzia', value: 'broker' },
    { label: 'Coverholder', value: 'coverholder' },
  ];

  /**
   * File Upload - Documenti
   */
  const [files, setFiles] = useState<string[]>([]);
  const [fileID, setFileID] = useState<string[]>([]);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [txtFileUploadValue, setTxtFileUploadValue] = useState('');
  const [tmpFileUploadValue, setTmpFileUploadValue] = useState<any>();
  const [selectedFileUpload, setSelectedFileUpload] = useState<string[]>([]);
  const [optionsFileUpload, setOptionsFileUpload] = useState<FileOption[]>([]);
  const [activeFileUpload, setActiveFileUpload] = useState(false);
  const [isAdderFileUpload, setIsAdderFileUpload] = useState(false);
  const [selectedType, setSelectedType] = useState<string>(CompanyFileTypes.MANDATE);

  const typeOptions = [
    { label: 'Mandato', value: CompanyFileTypes.MANDATE },
    { label: 'Tabella Provvigionale', value: CompanyFileTypes.COMMISSION_TABLE },
    { label: 'Altro', value: CompanyFileTypes.OTHER },
  ];

  const [fileUploadActive, setFileUploadActive] = useState(false);
  const toggleFileUploadActive = useCallback(() => setFileUploadActive((fileUploadActive) => !fileUploadActive), []);

  const [fileDeletedActive, setFileDeletedActive] = useState(false);
  const toggleFileDeletedActive = useCallback(() => setFileDeletedActive((fileDeletedActive) => !fileDeletedActive), []);

  /**
   * Logo
   */
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  const [logoUploaded, setLogoUploaded] = useState(false);

  const [isDirty, setIsDirty] = useState(false);
  const [success, setSuccess] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const [missingName, setMissingName] = useState(false);
  const [invalidUrl, setInvalidUrl] = useState(false);

  /** Delete modal */
  const [buttonDeleteSpinning, setButtonDeleteSpinning] = useState(false);
  const [deleteModalActive, setDeleteModalActive] = useState(false);

  const handleDeleteModal = useCallback(() => {
    setDeleteModalActive(!deleteModalActive);
  }, [deleteModalActive]);

  /** Loading markup */
  const loadingMarkup = isLoading ? <Loading /> : null;

  /** Toast */
  const toggleSuccess = useCallback(() => setSuccess((success) => !success), []);
  const toastMarkup = success ? <Toast content="Compagnia modificata con successo!" onDismiss={toggleSuccess} /> : null;

  const toggleDeleted = useCallback(() => setDeleted((deleted) => !deleted), []);
  const toastDeletedMarkup = deleted ? <Toast content="Compagnia eliminata con successo!" onDismiss={toggleDeleted} /> : null;

  /**
   * Data fetching
   */
  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/companies/${params.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setCompany({
            ...data.data,
            referent: data.data.referent
              ? { ...data.data.referent }
              : {
                  name: '',
                  lastname: '',
                  email: '',
                  phone: '',
                },
            billing: data.data.billing ? { ...data.data.billing } : { email: '', day: 1 },
          });

          // Files
          const fileArray = [];
          const fileNamesArray = [];
          const fileIdsArray = [];
          const fileOptions = [];
          const fileTypes = [];

          for (const item of data.data.documents) {
            fileArray.push('Document');
            fileNamesArray.push(item.title);
            fileIdsArray.push(item._id);
            fileOptions.push({
              label: `${item.title} - ${parseCompanyFileType(item.file_type)}`,
              value: item.key,
            });
            fileTypes.push(item.file_type);
          }
          setFiles(fileArray);
          setFileNames(fileNamesArray);
          setFileID(fileIdsArray);
          setOptionsFileUpload(fileOptions);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCompanyDetails();
  }, [params.id, update]);

  /**
   * Save data
   */
  const handleSave = useCallback(async () => {
    try {
      setSaveError(false);
      setButtonSpinning(true);

      let errFlag = false;

      // Check if company name is empty
      if (!company.name) {
        setMissingName(true);
        errFlag = true;
      }

      // Check if platform link is a valid URL
      if (company.platform_link && !/^(ftp|http|https):\/\/[^ "]+$/.test(company.platform_link)) {
        setInvalidUrl(true);
        errFlag = true;
      }

      if (errFlag) {
        return;
      }

      // Create form data
      const formData = new FormData();
      if (company.logo) {
        formData.append('document', company.logo);
      }
      if (company.name) {
        formData.append('name', company.name);
      }
      if (company.type) {
        formData.append('type', company.type);
      }
      if (company.referent) {
        formData.append('referent[name]', company.referent.name ?? '');
        formData.append('referent[lastname]', company.referent.lastname ?? '');
        formData.append('referent[email]', company.referent.email ?? '');
        formData.append('referent[phone]', company.referent.phone ?? '');
      }
      if (company.billing) {
        formData.append('billing[email]', company.billing.email ?? '');
        formData.append('billing[day]', company.billing.day?.toString() ?? '');
      }
      if (company.platform_link) {
        formData.append('platform_link', company.platform_link);
      }
      if (company.platform_username) {
        formData.append('platform_username', company.platform_username);
      }
      if (company.platform_password) {
        formData.append('platform_password', company.platform_password);
      }
      if (company?.phone) {
        formData.append('phone', company.phone);
      }

      const response = await axios.put(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/companies/${params.id}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });

      const data = response.data;

      if (data.status === 'success') {
        setSuccess(true);
        setUpdate(!update);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      setSaveError(true);
    } finally {
      setButtonSpinning(false);
    }
    setIsDirty(false);
  }, [params.id, company, update]);

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Modifiche non salvate"
      saveAction={{
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: () => navigate('/admin/companies'),
      }}
    />
  ) : null;

  /** Company drop zone logo */
  const handleDropZoneDrop = useCallback((_dropFiles: File[], acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    // Add necessary file properties for CompanyFile type
    const newLogo: CompanyFile = Object.assign(file, {
      key: file.name,
      title: file.name,
    });

    setCompany((prevCompany) => ({
      ...prevCompany,
      logo: newLogo,
    }));
    setLogoUploaded(true);
    setIsDirty(true);
  }, []);

  /** Handle input changes */
  const handleChange = (field: keyof Company | 'referent' | 'billing', value: any, subField?: string) => {
    setCompany((prevCompany) => {
      if (field === 'referent' && subField) {
        return {
          ...prevCompany,
          referent: {
            ...(prevCompany.referent || {}), // Fallback a un oggetto vuoto se 'referent' è undefined
            [subField]: value,
          },
        };
      } else if (field === 'billing' && subField) {
        return {
          ...prevCompany,
          billing: {
            ...(prevCompany.billing || {}), // Simile per 'billing' se necessario
            [subField]: value,
          },
        };
      } else {
        return { ...prevCompany, [field]: value };
      }
    });
    setIsDirty(true);
  };

  const uploadedLogo = logoUploaded && company.logo && (
    <LegacyStack alignment="center">
      <Thumbnail size="large" alt={company.logo.title} source={window.URL.createObjectURL(company.logo)} />
      <div>{company.logo.title}</div>
    </LegacyStack>
  );

  const existingLogo = company?.logo && (
    <LegacyStack alignment="center">
      <Thumbnail size="large" alt={company.logo.title} source={process.env.REACT_APP_BLOB_IMAGES_URL + company.logo.key} />
      <div>{company.logo.title}</div>
    </LegacyStack>
  );

  const logoUpload = !company?.logo && <DropZone.FileUpload />;

  /**
   * Delete company
   */
  const handleDelete = useCallback(async () => {
    try {
      setButtonDeleteSpinning(true);
      const response = await axios.delete(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/companies/${params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });

      const data = response.data;

      if (data.status === 'success') {
        handleDeleteModal();
        setDeleted(true);
        setTimeout(() => {
          navigate(`/admin/companies`);
        }, 2000);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      const status = axiosError.response?.status || 500;

      if (status === 409) {
        setConflictError(true);
      } else {
        setSaveError(true);
      }
      handleDeleteModal();
    } finally {
      setButtonDeleteSpinning(false);
    }
  }, [params.id, navigate, handleDeleteModal]);

  /**
   * Delete modal
   */
  const deleteModalMarkup = (
    <Modal
      open={deleteModalActive}
      onClose={handleDeleteModal}
      title="Elimina compagnia"
      primaryAction={{
        content: 'Elimina',
        onAction: handleDelete,
        loading: buttonDeleteSpinning,
        destructive: true,
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: handleDeleteModal,
        },
      ]}
    >
      <Modal.Section>
        <p>Sei sicuro di voler eliminare questa compagnia?</p>
      </Modal.Section>
    </Modal>
  );

  /**
   * File Uploading Handler
   */
  const toggleModalFileUpload = useCallback(() => setActiveFileUpload((activeFileUpload: boolean) => !activeFileUpload), []);
  const toggleIsAdderFileUpload = useCallback((e: boolean) => {
    setIsAdderFileUpload((isAdderFileUpload) => {
      isAdderFileUpload = e;
      return isAdderFileUpload;
    });
  }, []);

  const handleFileUploadAdd = useCallback(
    (e: any, fn: string, type: any) => {
      if (e === '' || e === undefined || fn === '' || fn === undefined || type === undefined || type === '') return;

      setIsUploading(true);

      setFiles((files) => {
        const newFiles = files;
        //@ts-ignore
        newFiles.push(e);
        return newFiles;
      });

      setFileNames((fileNames) => {
        const newFileNames = fileNames;
        //@ts-ignore
        newFileNames.push(fn);
        return newFileNames;
      });

      setOptionsFileUpload((optionsFileUpload) => {
        const newOptions = optionsFileUpload;
        //@ts-ignore
        newOptions.push({ label: `${fn.split('__')[1]} - ${type}`, value: fn });
        return newOptions;
      });

      const addFile = async () => {
        try {
          const fd = new FormData();
          fd.append('document', e);
          fd.append('type', type);

          const response = await axios.post(`${process.env.REACT_APP_API_URL ?? `/api`}/admin/companies/${params.id}/upload`, fd, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          });

          const data = response.data;
          if (data.status === 'success') {
            setFileUploadActive(true);
            setUpdate(!update);
          }
        } catch (error) {
          console.log(error);
          setSaveError(true);
        } finally {
          setIsUploading(false);
          toggleModalFileUpload();
        }
      };
      addFile();
    },
    [params.id, typeOptions, update, toggleModalFileUpload],
  );

  const handleConfirmFileUpload = useCallback(() => {
    if (isAdderFileUpload) {
      handleFileUploadAdd(tmpFileUploadValue, txtFileUploadValue, selectedType);
    }
    // else {
    //   handleFileUploadModify(tmpFileUploadValue, txtFileUploadValue, selectedFileUpload[0]);
    // }
  }, [isAdderFileUpload, handleFileUploadAdd, tmpFileUploadValue, txtFileUploadValue, selectedType, selectedFileUpload]);

  const handleTmpFileUploadValue = useCallback((e: any) => {
    setTmpFileUploadValue(e);
  }, []);

  const handleAddFileUpload = useCallback(
    (_dropFiles: any, acceptedFiles: any) => {
      toggleModalFileUpload();
      toggleIsAdderFileUpload(true);
      const blob = acceptedFiles[0];
      const file = new File([blob], params.id + '__' + acceptedFiles[0].name);
      setTxtFileUploadValue(file.name);
      // @ts-ignore
      setTmpFileUploadValue((tmpFileUploadValue) => file);
    },
    [toggleModalFileUpload, toggleIsAdderFileUpload, params.id],
  );

  const handleSelectionFileUpload = useCallback((e: any) => {
    setSelectedFileUpload(e);
  }, []);

  /**
   * Delete file
   */
  const handleDelFileUpload = useCallback(() => {
    // @ts-ignore
    if (selectedFileUpload[0] === '' || selectedFileUpload[0] === undefined) return;

    const modFileName = selectedFileUpload[0];
    // @ts-ignore
    const modFile = files[fileNames.indexOf(selectedFileUpload[0])];
    // @ts-ignore
    const modFileID = fileID[fileNames.indexOf(selectedFileUpload[0])];

    const delFile = async () => {
      try {
        const fileKey = selectedFileUpload[0];

        await axios.delete(`${process.env.REACT_APP_API_URL ?? `/api`}/admin/companies/${params.id}/files/${fileKey}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });

        setFileDeletedActive(true);
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
        setSaveError(true);
      }
    };
    delFile();

    setFiles((files) => {
      //@ts-ignore
      const indexOfDeletion = files.indexOf(modFile);
      files.splice(indexOfDeletion, 1);
      return files;
    });

    setFileNames((fileNames) => {
      //@ts-ignore
      const indexOfDeletion = fileNames.indexOf(modFileName);
      fileNames.splice(indexOfDeletion, 1);
      return fileNames;
    });

    setOptionsFileUpload((optionsFileUpload) => {
      //@ts-ignore
      const indexOfDeletion = optionsFileUpload.findIndex((op) => op.value === selectedFileUpload[0]);
      optionsFileUpload.splice(indexOfDeletion, 1);
      return optionsFileUpload;
    });

    setFileID((fileID) => {
      //@ts-ignore
      const indexOfDeletion = fileID.indexOf(modFileID);
      fileID.splice(indexOfDeletion, 1);
      return fileID;
    });

    handleSelectionFileUpload(['']);
  }, [selectedFileUpload, files, fileNames, fileID, handleSelectionFileUpload, params.id, user.id]);

  /**
   * Download single file
   */
  const handleOpenFile = useCallback(async () => {
    try {
      const fileKey = selectedFileUpload[0];

      const data = await fetch(`${process.env.REACT_APP_API_URL ?? `/api`}/admin/companies/${params.id}/files/${fileKey}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const response = await data.blob();

      // @ts-ignore
      saveAs(response, selectedFileUpload[0]);
    } catch (error) {
      console.log(error);
      setSaveError(true);
    }
  }, [selectedFileUpload, user.id]);

  const fileUpload = !tmpFileUploadValue && <DropZone.FileUpload />;
  const uploadedFile = tmpFileUploadValue && (
    <LegacyStack>
      <div>
        {/*
        // @ts-ignore */}
        {tmpFileUploadValue.name.split('__')[1]}{' '}
        <Text variant="bodySm" as="p">
          {tmpFileUploadValue.size} bytes
        </Text>
      </div>
    </LegacyStack>
  );

  const toastUploadedDocumentMarkup = fileUploadActive && <Toast content="Il documento è stato aggiunto correttamente" onDismiss={toggleFileUploadActive} />;
  const toastDeletedDocumentMarkup = fileDeletedActive && <Toast content="Il documento è stato rimosso correttamente" onDismiss={toggleFileDeletedActive} />;

  const fileUploadJSX = (
    <div>
      <LegacyStack vertical>
        <DropZone allowMultiple={false} onDrop={handleAddFileUpload}>
          <DropZone.FileUpload />
        </DropZone>
        <LegacyStack distribution={'center'}>
          {selectedFileUpload[0] && <Button onClick={handleOpenFile}>Scarica</Button>}
          {/*selectedFileUpload[0] && <Button onClick={handleModFileUpload}>Modifica</Button>*/}
          {selectedFileUpload[0] && <Button onClick={handleDelFileUpload}>Elimina</Button>}
        </LegacyStack>
        <OptionList onChange={handleSelectionFileUpload} options={optionsFileUpload} selected={selectedFileUpload} />
      </LegacyStack>

      <div>
        <Modal
          open={activeFileUpload}
          onClose={toggleModalFileUpload}
          title={isAdderFileUpload ? 'Conferma Aggiunta' : 'Conferma Modifica'}
          secondaryActions={[
            {
              content: 'Chiudi',
              onAction: toggleModalFileUpload,
            },
          ]}
        >
          <Modal.Section>
            <FormLayout>
              <FormLayout.Group>
                <DropZone onDrop={handleTmpFileUploadValue}>
                  {uploadedFile}
                  {fileUpload}
                </DropZone>
              </FormLayout.Group>
              <FormLayout.Group>
                <Select label="Tipologia di documento" options={typeOptions} onChange={(e) => setSelectedType(e)} value={selectedType} />
              </FormLayout.Group>
              <FormLayout.Group>
                <Button primary onClick={handleConfirmFileUpload} loading={isUploading}>
                  {isAdderFileUpload ? 'Conferma Aggiunta' : 'Conferma Modifica'}
                </Button>
              </FormLayout.Group>
            </FormLayout>
          </Modal.Section>
        </Modal>
      </div>
    </div>
  );

  /**
   * Banner & toast
   */
  const bannerMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const conflictBannerMarkup = conflictError && (
    <Layout.Section>
      <Banner title="Impossibile eliminare la compagnia" status="critical" onDismiss={() => setConflictError(false)}>
        <p>Non è possibile eliminare la compagnia poiché è associata a un prodotto.</p>
      </Banner>
    </Layout.Section>
  );

  /**
   * Page markup
   */
  const actualPageMarkup = company && (
    <Page
      title={company.name}
      titleMetadata={renderCompanyTypeBadge(company.type)}
      backAction={{ content: 'Compagnie', url: '/admin/companies' }}
      primaryAction={{ content: 'Elimina compagnia', onAction: handleDeleteModal, loading: buttonDeleteSpinning, destructive: true }}
    >
      <Layout>
        {/* Banner */}
        {bannerMarkup}
        {conflictBannerMarkup}

        {/* Dettagli compagnia */}
        <Layout.Section>
          <LegacyCard title="Dettagli compagnia" sectioned>
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  label="Nome"
                  value={company?.name || ''}
                  onChange={(value) => handleChange('name', value)}
                  autoComplete="off"
                  error={missingName && 'Il nome è obbligatorio'}
                />
                <Select label="Tipologia" options={companyTypes} value={company.type} onChange={(value) => handleChange('type', value)} />
              </FormLayout.Group>
              <FormLayout.Group>
                <DropZone accept={validImageTypes.join(',')} type="image" onDrop={handleDropZoneDrop} label="Logo">
                  {uploadedLogo}
                  {!uploadedLogo && existingLogo}
                  {!uploadedLogo && !existingLogo && logoUpload}
                </DropZone>
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.Section>

        {/* Contatti e piattaforma */}
        <Layout.Section>
          <LegacyCard sectioned title="Contatti e Piattaforma">
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  label="Link alla Piattaforma"
                  value={company.platform_link || ''}
                  onChange={(value) => handleChange('platform_link', value)}
                  autoComplete="off"
                  type="url"
                  error={invalidUrl && 'Inserisci un URL valido'}
                />
                <TextField
                  label="Numero di Telefono Azienda"
                  value={company.phone || ''}
                  onChange={(value) => handleChange('phone', value)}
                  autoComplete="off"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  label="Username piattaforma"
                  value={company.platform_username}
                  onChange={(value) => handleChange('platform_username', value)}
                  autoComplete="off"
                />
                <TextField
                  label="Password piattaforma"
                  value={company.platform_password}
                  onChange={(value) => handleChange('platform_password', value)}
                  autoComplete="off"
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.Section>

        {/* Dati Referente */}
        <Layout.Section>
          <LegacyCard sectioned title="Dati Referente">
            <FormLayout>
              <FormLayout.Group>
                <TextField label="Nome" value={company.referent?.name || ''} onChange={(value) => handleChange('referent', value, 'name')} autoComplete="off" />
                <TextField
                  label="Cognome"
                  value={company.referent?.lastname || ''}
                  onChange={(value) => handleChange('referent', value, 'lastname')}
                  autoComplete="off"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  label="Email"
                  value={company.referent?.email || ''}
                  onChange={(value) => handleChange('referent', value, 'email')}
                  autoComplete="off"
                />
                <TextField
                  label="Numero di Telefono"
                  value={company.referent?.phone || ''}
                  onChange={(value) => handleChange('referent', value, 'phone')}
                  autoComplete="off"
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.Section>

        {/* Dati Fatturazione */}
        <Layout.Section>
          <LegacyCard sectioned title="Dati Fatturazione">
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  label="Email"
                  value={company.billing?.email || ''}
                  onChange={(value) => handleChange('billing', value, 'email')}
                  autoComplete="off"
                />
                <Select
                  label="Giorno del Mese per la Fatturazione"
                  options={Array.from({ length: 31 }, (_, i) => ({
                    label: (i + 1).toString(),
                    value: (i + 1).toString(),
                  }))}
                  value={company.billing?.day ? company.billing.day.toString() : undefined}
                  onChange={(value) => handleChange('billing', parseInt(value, 10), 'day')}
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.Section>

        {/* Documents */}
        <Layout.Section>
          <LegacyCard title="Documenti vari">
            <LegacyCard.Section>{fileUploadJSX}</LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>

        <Layout.Section />
      </Layout>
    </Page>
  );

  /**
   * Loading page markup
   */
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingMarkup}
      {contextualSaveBarMarkup}
      {pageMarkup}
      {deleteModalMarkup}
      {toastMarkup}
      {toastDeletedMarkup}
      {toastUploadedDocumentMarkup}
      {toastDeletedDocumentMarkup}
    </Frame>
  );
}
