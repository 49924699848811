import { Navigation, SubNavigationItem } from '@shopify/polaris';
import {
  AnalyticsMajor,
  CartMajor,
  CirclePlusMajor,
  CustomersMajor,
  HomeMajor,
  NoteMajor,
  OrdersMajor,
  OrderStatusMinor,
  OrganizationMajor,
  ProductsMajor,
  SettingsMajor,
  StoreStatusMajor,
} from '@shopify/polaris-icons';

type BrokerMarkupProps = {
  toggleIsLoading: () => void;
  setNavItemActive: (navItem: string) => void;
  resourcesSubNavigationMarkup: SubNavigationItem[];
  bookIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export function BrokerMarkup({ toggleIsLoading, setNavItemActive, resourcesSubNavigationMarkup, bookIcon }: BrokerMarkupProps) {
  return (
    <>
      <Navigation.Section
        title="Home"
        items={[
          {
            label: 'Home',
            icon: HomeMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('home');
            },
            url: '/',
            exactMatch: true,
          },
          {
            label: 'Clienti',
            icon: CustomersMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('customers');
            },
            url: '/customers',
          },
          {
            label: 'Prodotti',
            icon: ProductsMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('questionnaires');
            },
            url: '/questionnaires',
          },
          {
            label: 'Preventivi',
            icon: NoteMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('quotes');
            },
            url: '/quotes',
          },
          {
            label: 'Polizze',
            icon: OrdersMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('policies');
            },
            url: '/policies',
          },
        ]}
      />
      <Navigation.Section
        title="Analisi assicurative"
        items={[
          {
            label: 'Retail e Famiglie',
            icon: StoreStatusMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('reports family');
            },
            url: '/reports/family',
          },
          {
            label: 'Business (beta)',
            icon: OrderStatusMinor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('reports');
            },
            url: '/reports',
          },
        ]}
      />
      <Navigation.Section
        title="Analytics"
        items={[
          {
            label: 'Analytics',
            icon: AnalyticsMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('analytics');
            },
            url: '/analytics',
          },
        ]}
      />
      <Navigation.Section
        title="Guide Risorse"
        items={[
          {
            label: 'Guide',
            icon: bookIcon as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('guide');
            },
            url: '/guide',
          },
          {
            label: 'Risorse Marketing',
            icon: bookIcon as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('resources');
            },
            secondaryAction: {
              url: '/resources',
              accessibilityLabel: 'Altri',
              icon: CirclePlusMajor as any,
              onClick: () => {
                toggleIsLoading();
                setNavItemActive('resources');
              },
            },
            url: '/resources',
            subNavigationItems: resourcesSubNavigationMarkup,
          },
        ]}
      />
      <Navigation.Section
        title="Organizzazione"
        fill
        items={[
          {
            label: 'Acquista analisi',
            icon: CartMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('analisi');
            },
            url: '/organization/reports/buy',
          },
          {
            label: 'Dettagli organizzazione',
            icon: OrganizationMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('settings');
            },
            url: '/organization/settings',
            matchPaths: ['/organization/settings', '/organization/settings/*'],
          },
        ]}
      />
      <Navigation.Section
        items={[
          {
            label: 'Impostazioni',
            icon: SettingsMajor as any,
            onClick: () => {
              toggleIsLoading();
              setNavItemActive('settings');
            },
            url: '/settings',
            matchPaths: ['/settings', '/settings/*'],
          },
        ]}
      />
    </>
  );
}
