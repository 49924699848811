import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  LegacyCard,
  Frame,
  Layout,
  Page,
  Text,
  Banner,
  Filters,
  EmptyState,
  FilterInterface,
  IndexTable,
  useIndexResourceState,
  Toast,
  Select,
  Loading,
  FormLayout,
  Modal,
  ButtonGroup,
  Button,
  TextField,
  Checkbox,
  Icon,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup } from '../../../components';
import axios, { AxiosError } from 'axios';
import { Automation } from '../../../types';
import { useUser } from '../../../utils/PrivateRoute';
import { AlertMinor } from '@shopify/polaris-icons';

export function Automations() {
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [error, setError] = useState(false);
  /** Empty fields */
  const [emptyFields, setEmptyFields] = useState({
    name: false,
  });

  const [automationId, setAutomationId] = useState<string | null>(null);
  const [name, setName] = useState('');
  const [available, setAvailable] = useState<boolean>(true);
  const [automationError, setAutomationError] = useState(false);
  const orderStatusOptions = [
    { label: 'Polizza', value: 'paid' },
    { label: 'Preventivo', value: 'quote' },
  ];
  const [orderStatus, setOrderStatus] = useState<'quote' | 'paid'>('paid');

  const { user } = useUser();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const [automationUpdatedActive, setAutomationUpdatedActive] = useState(false);
  const toggleAutomationUpdatedActive = useCallback(() => setAutomationUpdatedActive((automationUpdatedActive) => !automationUpdatedActive), []);

  const [automationCreatedActive, setAutomationCreatedActive] = useState(false);
  const toggleAutomationCreatedActive = useCallback(() => setAutomationCreatedActive((automationCreatedActive) => !automationCreatedActive), []);

  const [addAutomationModalOpen, setAddAutomationModalOpen] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: boolean) => {
    setMobileNavigationActive(!data);
  };

  //   /**
  //    * Automation states
  //    */
  const [taggedWith, setTaggedWith] = useState('');
  const [queryValue, setQueryValue] = useState('');
  const [sortValue, setSortValue] = useState('DATE_CREATED_DESC');
  const [items, setItems] = useState<Automation[]>([]);
  const [frontItems, setFrontItems] = useState<Automation[]>([]);

  const handleTaggedWithChange = useCallback((value: any) => setTaggedWith(value), []);
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(''), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleTaggedWithRemove]);

  const resourceName = {
    singular: 'automazione',
    plural: 'automazioni',
  };

  const [isEditing, setIsEditing] = useState(false);

  /**
   * Data fetching:
   * - fetch automations
   */
  useEffect(() => {
    const fetchAutomations = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/automations`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
          timeout: 10000,
        });
        const data = response.data;
        if (data.status === 'success') {
          setItems(data.data);
          setFrontItems(data.data);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          console.log(axiosError.response);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchAutomations();
  }, [update, automationError]);

  /**
   * Handle add Automation
   */
  const handleAddAutomation = useCallback(async () => {
    try {
      setButtonSpinning(true);

      let flag = false;
      if (name === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, name: true }));
        flag = true;
      }

      if (flag) {
        setButtonSpinning(false);
        return;
      }

      const response = await axios.post(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/automations`,
        {
          name: name,
          available: available,
          order_status: orderStatus,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        setUpdate(!update);
        setName('');
        setAvailable(true);
        setOrderStatus('paid');
        setAddAutomationModalOpen(false);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        console.log(axiosError.response);
        setError(true);
      }
    } finally {
      setButtonSpinning(false);
    }
  }, [emptyFields, name, available, orderStatus, update]);

  /**
   * Handle update Automation
   */
  const handleUpdateAutomation = useCallback(async () => {
    try {
      setButtonSpinning(true);

      let flag = false;
      if (name === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, name: true }));
        flag = true;
      }

      if (flag) {
        setButtonSpinning(false);
        return;
      }

      const response = await axios.put(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/automations/${automationId}`,
        {
          name: name,
          available: available,
          order_status: orderStatus,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        setUpdate(!update);
        setName('');
        setAvailable(true);
        setOrderStatus('paid');
        setIsEditing(false);
        setAddAutomationModalOpen(false);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        console.log(axiosError.response);
        setError(true);
      }
    } finally {
      setButtonSpinning(false);
    }
  }, [emptyFields, name, available, orderStatus, update, automationId]);

  /**
   * Name change
   */
  const handleNameChange = useCallback(
    (value: string) => {
      if (emptyFields.name) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, name: false }));
      }
      setName(value);
    },
    [emptyFields],
  );

  /**
   * Available change
   */
  const handleAvailableChange = useCallback(
    (value: boolean) => {
      setAvailable(value);
    },
    [emptyFields],
  );

  /**
   * Available change
   */
  const handleOrderStatusChange = useCallback(
    (value: 'quote' | 'paid') => {
      setOrderStatus(value);
    },
    [emptyFields],
  );

  /**
   * Handle edit template modal
   */
  const handleEdit = useCallback(
    (id: string) => {
      const item = items.find((item: Automation) => item._id === id);
      if (item) {
        setAddAutomationModalOpen(true);
        setIsEditing(true);
        setAutomationId(id);
        setName(item.name);
        setAvailable(item.available);
        setAutomationError(item.error);
        setOrderStatus(item.order_status);
      }
    },
    [items],
  );

  /**
   * Filtering
   */
  function disambiguateLabel(key: string, value: string | null) {
    switch (key) {
      case 'taggedWith':
        return `Tagged with ${value}`;
      default:
        return '';
    }
  }

  function isEmpty(value: string | any[] | null) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const filters: FilterInterface[] = [];

  const appliedFilters = !isEmpty(taggedWith)
    ? [
        {
          key: 'taggedWith',
          label: disambiguateLabel('taggedWith', taggedWith),
          // @ts-ignore
          onRemove: handleTaggedWithRemove,
        },
      ]
    : [];

  /** Activate bot automation if blocked */
  const activateAutomationBot = async (automation_id: string) => {
    try {
      const response = await axios.get(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/orders/automation/activate/${automation_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );

      if (response.status === 200) {
        setAutomationError(false);
      }
    } catch (error: AxiosError | any) {
      if (error.response) {
        const errorResponse = error.response.data.message || error.response.data.status;
        // Make lower case and capitalize first letter
        const formattedError = errorResponse.charAt(0).toUpperCase() + errorResponse.slice(1).toLowerCase();
        setError(formattedError);
      }
    }
  };

  useEffect(() => {
    const filterItems = () => {
      const filteredItems = items.filter((item) => {
        return item.name.toLowerCase().includes(queryValue.toLowerCase());
      });
      setFrontItems(filteredItems);
    };
    filterItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  /**
   * Handle sort
   */
  useEffect(() => {
    if (sortValue === 'DATE_CREATED_DESC') {
      const tmp = [...items];
      // @ts-ignore
      tmp.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CREATED_ASC') {
      const tmp = [...items];
      // @ts-ignore
      tmp.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
      setFrontItems(tmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  /**
   * Index table handlers
   */
  const resourceIDResolver = (items: { _id: any }) => {
    return items._id;
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(frontItems, {
    resourceIDResolver,
  });

  /**
   * Render row
   */
  const rowMarkup = frontItems.map((item: Automation, index: number) => {
    const { _id: id, name, available, error, order_status } = item;

    const orderStatus = orderStatusOptions.find((status) => status.value === order_status);

    return (
      <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
        <IndexTable.Cell>
          <Text as="span" fontWeight="semibold">
            {name}
          </Text>
          {error && (
            <span
              style={{
                marginLeft: '8px',
                display: 'inline-flex',
                alignItems: 'center',
                verticalAlign: 'middle',
                backgroundColor: '#ffbaba',
                borderRadius: '5px',
                padding: '2px',
              }}
            >
              <Icon source={AlertMinor as any} color="critical" />
            </span>
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>{available ? 'Si' : 'No'}</IndexTable.Cell>
        <IndexTable.Cell>{orderStatus ? orderStatus.label : ''}</IndexTable.Cell>
        <IndexTable.Cell>
          <div style={{ float: 'right' }}>
            <ButtonGroup segmented>
              <Button size="slim" onClick={() => handleEdit(id)}>
                Modifica
              </Button>
            </ButtonGroup>
          </div>
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  /**
   * Automation modal
   */
  const automationModalMarkup = (
    <Modal
      open={addAutomationModalOpen}
      onClose={() => {
        setAddAutomationModalOpen(false);
        setIsEditing(false);
        setUpdate(!update);
        setName('');
        setAvailable(true);
        setOrderStatus('paid');
        setIsEditing(false);
        setEmptyFields({
          name: false,
        });
      }}
      title={!isEditing ? "Aggiungi un'automazione" : "Modifica l'automazione"}
      primaryAction={{
        content: !isEditing ? 'Aggiungi automazione' : 'Modifica automazione',
        onAction: () => (!isEditing ? handleAddAutomation() : handleUpdateAutomation()),
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: () => {
            setAddAutomationModalOpen(false);
            setIsEditing(false);
            setUpdate(!update);
            setName('');
            setAvailable(true);
            setOrderStatus('paid');
            setIsEditing(false);
            setEmptyFields({
              name: false,
            });
          },
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            label="Nome"
            value={name}
            onChange={handleNameChange}
            placeholder="Nome dell'automazione"
            autoComplete="off"
            error={emptyFields.name && 'Il nome è obbligatorio'}
          />
          <Checkbox label="Disponibile" checked={available} onChange={handleAvailableChange} />
          <Select
            label="Stato ordine (lo stato degli ordini per i quali sarà disponibile l'automazione)"
            value={orderStatus}
            options={orderStatusOptions}
            onChange={handleOrderStatusChange}
          />
        </FormLayout>
        <br></br>
        {automationError && (
          <>
            <Banner title="Si è verificato un errore critico nell'automazione" status="critical" onDismiss={() => setError(false)}>
              <p>L'automazione non può essere utilizzata al momento, è rischiesto intervento tecnico.</p>
            </Banner>
            <br></br>
            <Button onClick={() => activateAutomationBot(automationId as string)}>Riattiva automazione</Button>
          </>
        )}
      </Modal.Section>
    </Modal>
  );

  /**
   * Empty list markup
   */
  const emptyListMarkup = !items.length && (
    <EmptyState
      heading="Crea e gestisci le tue automazioni"
      image="https://cdn.shopify.com/shopifycloud/web/assets/v1/87c89b0a776f2d13fdde186958e6a83c28fd4a6ca1ff7f04bb16e6629bb8f6de.svg"
      action={{
        content: "Aggiungi un'automazione",
        onAction: () => setAddAutomationModalOpen(true),
        loading: buttonSpinning,
      }}
    ></EmptyState>
  );

  /**
   * Banner & toast markups
   */
  const bannerMarkup = error && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const toastAutomationUpdatedMarkup = automationUpdatedActive && (
    <Toast content="L'automazione è stata modficata con successo." onDismiss={toggleAutomationUpdatedActive} />
  );

  const toastAutomationCreatedMarkup = automationCreatedActive && (
    <Toast content="L'automazione è stata creata con successo." onDismiss={toggleAutomationCreatedActive} />
  );

  /**
   * Page markup
   */
  useEffect(() => {
    if (items.length > 0) setIsLoading(false);
  }, [items]);

  const actualPageMarkup = (
    <Page
      title="Automazioni"
      primaryAction={{
        content: "Aggiungi un'automazione",
        onAction: () => setAddAutomationModalOpen(true),
        loading: buttonSpinning,
      }}
    >
      <Layout>
        {/* Banner */}
        {bannerMarkup}

        {/* Templates list */}
        <Layout.Section>
          <LegacyCard>
            <div style={{ padding: '16px', display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <Filters
                  queryValue={queryValue}
                  filters={filters}
                  // @ts-ignore
                  appliedFilters={appliedFilters}
                  queryPlaceholder={'Filtra automazioni'}
                  onQueryChange={setQueryValue}
                  onQueryClear={handleQueryValueRemove}
                  onClearAll={handleClearAll}
                />
              </div>
              <div style={{ paddingLeft: '0.4rem' }}>
                <Select
                  labelInline
                  label="Ordina per"
                  options={[
                    { label: 'Data aggiunta automazione (dalla più recente)', value: 'DATE_CREATED_DESC' },
                    { label: 'Data aggiunta automazione (dalla meno recente)', value: 'DATE_CREATED_ASC' },
                  ]}
                  value={sortValue}
                  onChange={(selected) => {
                    setSortValue(selected);
                  }}
                />
              </div>
            </div>
            <IndexTable
              resourceName={resourceName}
              emptyState={emptyListMarkup}
              loading={isLoading}
              itemCount={frontItems.length}
              selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
              hasMoreItems
              onSelectionChange={handleSelectionChange}
              headings={[{ title: 'Nome' }, { title: 'Disponibile' }]}
            >
              {rowMarkup}
            </IndexTable>
          </LegacyCard>
        </Layout.Section>

        <Layout.Section />
      </Layout>
    </Page>
  );

  const loadingPageMarkup = isLoading && <Loading />;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingPageMarkup}
      {actualPageMarkup}
      {automationModalMarkup}
      {toastAutomationUpdatedMarkup}
      {toastAutomationCreatedMarkup}
    </Frame>
  );
}
