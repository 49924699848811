import {
  Pagination,
  LegacyCard,
  EmptyState,
  ResourceItem,
  ResourceList,
  Text,
  Thumbnail,
  HorizontalStack,
  LegacyFilters,
  Spinner,
  Link,
  VerticalStack,
} from '@shopify/polaris';
import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './CompaniesList.module.css';
import { Company } from '../../types';
import { renderCompanyTypeBadge } from '../../utils/Common';

type Props = {
  companies: Array<Company> | [];
};

export function CompaniesList({ companies }: Props) {
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [queryValue, setQueryValue] = useState('');
  const [sortValue, setSortValue] = useState('NAME_ASC');
  const [items, setItems] = useState(companies);
  const [frontItems, setFrontItems] = useState(companies.slice(0, 50));
  const totalItems = companies.length || 0;
  const [isLoading, setIsLoading] = useState(false);

  // Use page number to set current group of companies
  const [pageNumber, setPageNumber] = useState(1);

  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  const resourceName = {
    singular: 'compagnia',
    plural: 'compagnie',
  };

  const filters: any = [];

  // Filtering
  useEffect(() => {
    setIsLoading(true);
    const filterItems = () => {
      const filteredItems = companies.filter((item: Company) => {
        return item.name.toLowerCase().includes(queryValue.toLowerCase());
      });
      setItems(filteredItems);
      setFrontItems(filteredItems.slice(0, 50));
      setIsLoading(false);
    };
    filterItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, companies]);

  const handleQueryChange = useCallback((e: string) => {
    setQueryValue(e);
  }, []);

  const filterControl = (
    <LegacyFilters
      queryValue={queryValue}
      filters={filters}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleClearAll}
    ></LegacyFilters>
  );

  /**
   * Handle sort
   */
  useEffect(() => {
    setIsLoading(true);
    const sortedItems = [...items];
    if (sortValue === 'NAME_DESC') {
      sortedItems.sort((a, b) => (b.name > a.name ? 1 : a.name > b.name ? -1 : 0));
    } else if (sortValue === 'NAME_ASC') {
      sortedItems.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    }
    setItems(sortedItems);
    setFrontItems(sortedItems.slice(0, 50));
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  const onSelect = useCallback(
    (company: Company) => {
      navigate(`/admin/companies/${company._id}`);
    },
    [navigate],
  );

  /**
   * Render item
   */
  function renderItem(item: Company, index: string) {
    const media =
      item.logo && item.logo.title && item.logo.key ? (
        <Thumbnail size="medium" alt={item.logo.title} source={process.env.REACT_APP_BLOB_IMAGES_URL + item.logo.key} />
      ) : undefined;

    const companyUrl = item.platform_link ?? undefined;

    return (
      <ResourceItem id={index} onClick={() => onSelect(item)} sortOrder={Number(index)} accessibilityLabel={`View details for ${item.name}`}>
        <HorizontalStack blockAlign="center" align="space-between">
          <HorizontalStack blockAlign="center" gap={'5'}>
            {media}
            <VerticalStack gap="050">
              <Text as="span" fontWeight="semibold">
                {item.name}
              </Text>
              <div>{renderCompanyTypeBadge(item.type)}</div>
            </VerticalStack>
          </HorizontalStack>
          {companyUrl && (
            <Link url={companyUrl} target="_blank" external>
              Accedi
            </Link>
          )}
        </HorizontalStack>
      </ResourceItem>
    );
  }

  function resolveItemIds({ id }: any) {
    return id;
  }

  const emptyStateMarkup = !items.length ? (
    <LegacyCard>
      <LegacyCard.Section>
        <EmptyState heading="Gestisci le compagnie" image="https://cdn.shopify.com/shopifycloud/web/assets/v1/e7b58a8b2e612fe6cf6f8c9e53830b70.svg">
          <p>Qua è dove puoi gestire le compagnie</p>
        </EmptyState>
      </LegacyCard.Section>
    </LegacyCard>
  ) : undefined;

  /**
   * Pagination
   */
  const handlePreviousPage = useCallback(() => {
    setPageNumber((prevPageNumber) => {
      const newPageNumber = prevPageNumber - 1;
      setFrontItems(items.slice((newPageNumber - 1) * 50, newPageNumber * 50));
      return newPageNumber;
    });
  }, [items]);

  const handleNextPage = useCallback(() => {
    setPageNumber((prevPageNumber) => {
      const newPageNumber = prevPageNumber + 1;
      setFrontItems(items.slice(newPageNumber * 50, (newPageNumber + 1) * 50));
      return newPageNumber;
    });
  }, [items]);

  const paginationMarkup =
    items.length > 0 ? (
      <div className={styles.Pagination}>
        <Pagination
          hasPrevious={queryValue === null && pageNumber !== 1}
          hasNext={queryValue === null && pageNumber !== Math.ceil(totalItems / 50)}
          onPrevious={handlePreviousPage}
          onNext={handleNextPage}
        />
      </div>
    ) : null;

  /**
   * Markup with items
   */
  const companyListMarkup = (
    <LegacyCard>
      {isLoading ? (
        <Spinner size="large" />
      ) : (
        <ResourceList
          emptyState={emptyStateMarkup}
          resourceName={resourceName}
          items={frontItems}
          renderItem={renderItem}
          selectedItems={selectedItems}
          onSelectionChange={setSelectedItems}
          resolveItemId={resolveItemIds}
          filterControl={filterControl}
          sortValue={sortValue}
          sortOptions={[
            { label: 'Nome Z - A', value: 'NAME_DESC' },
            { label: 'Nome A -Z', value: 'NAME_ASC' },
          ]}
          onSortChange={(selected) => {
            setSortValue(selected);
          }}
          totalItemsCount={totalItems}
          loading={isLoading}
        />
      )}
      {paginationMarkup}
    </LegacyCard>
  );

  return companyListMarkup;
}
